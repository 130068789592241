<script setup lang="ts">
import { onErrorCaptured, ref } from "vue";
import { RouterView } from "vue-router";
import AppLayout from "./components/layouts/AppLayout.vue";
import UnexpectedError from "./components/layouts/unexpected-error.vue";
import { useNotificationsStore } from "@/stores/notification-store";
import socket from "@/modules/notifications";
const isUnexpectedError = ref(false);

const notificationsStore = useNotificationsStore();

// remove any existing listeners (after a hot module replacement)
socket.off();
notificationsStore.bindEvents();

onErrorCaptured(() => {
  // Setting this to true will display the generic error component
  // Locally we don't want to do that as it will cause the page to lose state
  // which makes debugging harder
  if (import.meta.env.PROD) {
    isUnexpectedError.value = true;
  }

  /**
   * @TODO if you want to add the unique error id to show to the UI so that users
   * can have a reference to that specific error, you can return `false` from this callback
   * which will stop the error propagation.
   * You can then do `const eventId = Sentry.captureException(...)` and use the variable
   * in the template.
   * Be careful when doing this as Sentry applies some formatting and additional context when reporting
   * errors, so make sure to check @sentry/vue/error-handler file's source code and implement the same
   * logic here
   */
});
</script>

<template>
  <unexpected-error v-if="isUnexpectedError" />

  <AppLayout v-else>
    <RouterView />
  </AppLayout>
</template>
