<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <circle cx="24" cy="24" r="24" fill="#D70B3D"/>
    <rect x="12" y="6" width="24" height="36.439" fill="url(#pattern0)"/>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_564_5420" transform="translate(-0.0303626) scale(0.0207985 0.0136986)"/>
      </pattern>
      <image id="image0_564_5420" width="51" height="73" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAABJCAYAAACQGB6NAAAACXBIWXMAAAsSAAALEgHS3X78AAAD60lEQVRogd1b4XWbMBC+9PV/6AShE5RMUDpB2KCMwAZ1NvAIbFCPYG/gTBB7guIJ6FN6cq9XCe4kgfzyvcezAwj06bv7OMnkbhxHCEQLAH1o4yXwIeKaHRK6GcQoYxoeAKC+FTKhylgCX98DmYp87xL1JRqxyhg8AUB5A1ySKGOwSdCXaIQYgFHh1bH/EwAMOcmEKMNVscieOyFkfO5lyBSR/YlCSmXuAaDJxOMNITkz1eCc09m0ysw9IB9yljhaMr4Qo3hXZLKVOKnDzCKLTWsMwNjuL8W1PwPAKaxbYdAoow2d1UscFxnfw0+SLxTf136I+pTZOvZpycDquWNyhm3V+Adbtr8cx3EYdRgc119s8134hF1u2f5aScZ1jdXJ9KQzNTvWKcmccpNpSWcGDD16fKck1OQkUzpGtyDHzfejgsw+JxkgeWNxZIQqpSHwcE2+TT009+zvL8yyj8qicnmbnhid1jPC3LI3CnUGbF+uHWY8byi43WoNYcQ8SmrbcyfwvKGgOaA1BIpkas2d0M90glq21hBciFJr7oRm5ubcstsZNaUIUmvuhEJwc27ZdhCmVNVArJaEsSQX+onB6NZSS0JmK7wZt2y+1Ug6Nq9Gn1oSMnN5QyEJhwLPC3U/igEHqJKSkeSNlhB1wFRqddIFjSOWMxocsCTaYfs5tLiZpaoQPEtHUJo3PgxYJXSO6QTfTIJvA9TaSJUxC+I/wwbMiTOqZjffklSL934SXPNZSka7ZqbFmYSk+eQ/WpUkDB9iyQDeJDSetXhhylFyDZLiaqnImEW9HyuR4TgwcoDRUuHipPncGTKF4rdI27gNcLeUOJCQvDqlIdNjJ32y+lCg5DV+3mcidsE+HK0t8qLwhPtaReVao4WnqMM0uC6W0JzpcX3YBamVWpRENYmtxuDxGmpsdKVlu0a5gkwJUqv2T7XucrMphXzQKFehak2kiVwwAv7mt2c0YydWUuVKPGcXUL50milAqpmihlwjNJGdq/3cQzMk5CQ4k2fE3lNVWxOxz8Ejhq83hCUVwFKEKC4s5yRThv8gLWfWIEQRRE5Tm61NiIKSc/1E+QbtuzM5CVnceQ8oyRQ4OjmLTC8Z7RsaA5YoL/F9CsJlqlHI+2Y5CU0aQegLp7kVciLm9fmbIxRDBjIQmpx6xJKBFQm9kPm/EzH/2MBRKN+vmayzQpCSTHZ8zNQBn4qud9r4vt73T0gpyLTk9V9XJ4vEFYM3b1KQ6ZGMXYxL3XkO7zLYkjlTk61KuK72zafOkjmzZzetyIpoPbEAHoycblaQ0KwVi/KPvhrt1qy5YqHpUi/ZfGZtGGOhBI2xuMkAwG9fZ1aooWGLzAAAAABJRU5ErkJggg=="/>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "secondary-logo"
}
</script>

<style scoped>

</style>