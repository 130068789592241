<template>
  <font-awesome-icon :icon="icon" />
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/**
 * @docs
 * To import new icons, use the fontawesome.plugin.ts file.
 * After you import and register an icon through `library.add`, the icon will be available
 * for usage from this component.
 */

// Example
// fontawesome.plugin.ts
// import { faUserSecret } from "@fortawesome/pro-regular-svg-icons";
// library.add(faUserSecret)
// Usage
// <t-icon is="user-secret" />
// Do note, you don't have to pass `fa-user-secret` but `user-secret`
// To use icons with different stylings, like `solid` or `light`, see the props of the component

export default defineComponent({
  name: "T-Icon",
  components: {
    FontAwesomeIcon,
  },
  props: {
    // Use this prop to specify icons with `regular` styling
    regular: {
      type: Boolean,
      default: true,
    },

    // Use this prop to specify icons with `solid` styling
    solid: Boolean,

    // Use this prop to specify icons with `light` styling
    light: Boolean,

    // Use this prop to specify icons with `duotone` styling
    duotone: Boolean,

    // Use this prop to specify icons with `sharp` styling
    sharp: Boolean,

    is: {
      type: String,
      required: true,
    },

    /**
     * @TODO
     * Icons adjust their sizing by inheriting the `font-size` property from some of
     * the parent elements.
     * We can standardize them later on when we have concrete examples,
     * if you need to modify the size just add one of the
     * tailwind font size classes on the icon.
     */
    size: {
      type: String,
    },
  },
  setup(props, context) {
    const icon = computed(() => {
      let prefix = "fa-regular";

      if (props.solid) {
        prefix = "fa-solid";
      } else if (props.light) {
        prefix = "fa-light";
      } else if (props.duotone) {
        prefix = "fa-duotone";
      } else if (props.sharp) {
        prefix = "fa-sharp";
      }

      return `${prefix} fa-${props.is}`;
    });

    return {
      icon,
    };
  },
});
</script>
