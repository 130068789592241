import { defineStore } from "pinia";

export const useUIStore = defineStore({
  id: "ui",
  state: () => ({
    navigationIsDisabled: false,
    isSubnavOpen: false,
    isOpenMonitoring: true,
    isOpenAnalysis: true,
    isSlimMenuShown: false,
    isMenuClosed: false,
    isNotificationOpen: false,
    specialCompanyIds: [
      1, // Yanko nongod
      4, // SEM BG
      13, // Digital A Ltd
      19, // Bozov
      20, // Suport Digital A
      53, // Serpact Team
      56, // Stevia for life
      79,
      751, // LOCAL DIMITAR
    ],
  }),
  actions: {
    disableNavigation() {
      this.navigationIsDisabled = true;
    },
    isCompanySpecial(): boolean {
      const companyId = localStorage.getItem("companyId");
      return this.specialCompanyIds.includes(Number(companyId));
    },
    toggleSideBar() {
      this.isSubnavOpen = !this.isSubnavOpen;
    },
    toggleInsideMenu() {
      this.isOpenMonitoring = !this.isOpenMonitoring;
    },
    toggleInsideMenuAnalysis() {
      this.isOpenAnalysis = !this.isOpenAnalysis;
    },
  },
});
