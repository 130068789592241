<template>
  <component :is="layout" v-if="route.name">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import defaultLayout from "./defaultLayout.vue";
import authLayout from "./authLayout.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import onboardingLayout from "@/components/layouts/onboardingLayout.vue";
import { useHead } from "@unhead/vue";
import Hotjar from "@hotjar/browser";

const siteId = 3639889;
const hotjarVersion = 6;


if (import.meta.env.PROD) {
Hotjar.init(siteId, hotjarVersion);
  useHead({
    title: "Trackian",
    noscript: [
      {
        innerHTML: `<iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-T7RLSD6R"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>`,
        tagPosition: "bodyOpen",
      },
    ],

    script: [
      {
        innerHTML: `window.dataLayer = window.dataLayer || [];`,
        tagPosition: "bodyOpen",
      },

      {
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T7RLSD6R');`,
        tagPosition: "bodyOpen",
      },
    ],
  });
}

const route = useRoute();
const layout = computed(() => {
  const layout = route.meta?.layout ?? "default";

  if (layout === "auth") {
    return authLayout;
  }
  if (layout === "onboarding") {
    return onboardingLayout;
  }

  return defaultLayout;
});
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
