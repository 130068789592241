// This component gives access to the dom element passed in its default slot

import { cloneVNode, defineComponent, type VNode, Fragment } from "vue";

const findFirstNonFragmentVNode = (children: VNode[]): VNode | null => {
  // If the first child is a fragment, look into its own children recursively
  // This happens when slots are passed to child components
  if (children[0]?.type === Fragment) {
    return findFirstNonFragmentVNode(children[0].children);
  }

  return children[0];
};

// Any attribute or even added to it will be added to its child element instead
export const SlotProxy = defineComponent({
  setup(props, context) {
    return () => {
      const slot = context.slots.default?.();

      if (!slot) {
        return null;
      }

      if (slot?.[0]?.children?.length > 1) {
        return console.warn(
          `T-Popover's button slot expects a single child element`,
        );
      }

      const first = findFirstNonFragmentVNode(slot[0].children);

      return first ? cloneVNode(first, context.attrs, true) : null;
    };
  },
});
