import type { BaseUseQueryOptions } from "@/modules/common/types";
import { useDefaultAxios } from "@/plugins/axios-default.plugin";
import { useQuery } from "vue-query";
import type { Company } from "@/stores/user-store";
import type { Connection } from "@/modules/common/types";
import type { Ref } from "vue";
import { useDebounce } from "@vueuse/core";

export type UseCompaniesQueryParams = {
  page?: number;
  take?: number;
  reverse?: boolean;
  searchQuery?: string;
};

export function useCompaniesQuery(
  params: Ref<UseCompaniesQueryParams>,
  options?: BaseUseQueryOptions,
) {
  const axios = useDefaultAxios();
  const debouncedParams = useDebounce(params, 330);

  return useQuery({
    ...options,
    keepPreviousData: true,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    queryKey: ["companies", debouncedParams],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<Connection<Company>>("/companies", {
        signal,
        params: debouncedParams.value,
      });

      return data;
    },
  });
}
