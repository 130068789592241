<template>
  <div
    :style="{
      paddingLeft: leftPadding + 'px',
    }"
  >
    <aside class="flex fixed top-0 left-0 h-screen z-[100]">
      <MainNavigation class="h-full bg-gray-100" />

      <nav
        v-if="showSettingsNavigation"
        class="h-full overflow-y-auto w-[210px] pl-8 pr-6 pt-10 pb-8 shadow-md space-y-8"
      >
        <h5 class="font-bold text-[18px]">Settings</h5>

        <router-link
          v-for="link in settingsPageLinks"
          :key="link.label"
          :to="{ name: link.route }"
          class="block text-base font-medium text-black transition duration-200 hover:text-primary"
          exact-active-class="text-primary"
        >
          {{ link.label }}
        </router-link>
      </nav>
    </aside>

    <main class="flex-1 pb-8" @click="handleCloseNav()">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import { mapWritableState } from "pinia";
import { useUIStore } from "@/stores/ui-store";
import MainNavigation from "@/components/navigation/index.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import Gateway from "@/modules/notifications/views/gateway.vue";

export default {
  name: "defaultLayout",
  components: { MainNavigation },
  setup(props, context) {
    const route = useRoute();
    const store = useUIStore();

    const showSettingsNavigation = false;

    const leftPadding = computed(() => {
      let initialValue = showSettingsNavigation.value ? 210 : 0;

      if (store.isSlimMenuShown) {
        return initialValue + 80;
      }

      if (store.navigationIsDisabled == true) {
        return initialValue + 0;
      }

      return initialValue + 288;
    });

    return {
      leftPadding,
      showSettingsNavigation,
      route,
    };
  },
  computed: {
    ...mapWritableState(useUIStore, [
      "isSubnavOpen",
      "isSlimMenuShown",
      "isNotificationOpen",
    ]),
  },
  data: () => ({
    settingsPageLinks: [
      { label: "User Profile", route: "settings_user_profile" },
      { label: "Change Password", route: "settings_change_password" },
      { label: "Connect platforms", route: "settings_connect_platforms" },
      { label: "Company Profile", route: "settings_company_profile" },
      { label: "Company Members", route: "settings_company_members" },
      { label: "Invitations", route: "settings_invitations" },
      { label: "Notification settings", route: "settings_notifications" },
      // { label: "Billing settings", route: "billing_notifications" },
      // { label: "Anomaly detection", route: "anomaly_detection" },
      { label: "Payment method", route: "settings_payment" },
      { label: "Billing history", route: "settings_history" },
      { label: "Upgrade plan", route: "settings_plan" },
    ],
  }),
  methods: {
    handleCloseNav() {
      if (this.isSubnavOpen === true) {
        // this.isSubnavOpen = false;
        // this.isSlimMenuShown = false;
      }
      // this.isSlimMenuShown = false;
      this.isNotificationOpen = false;
    },
  },
};
</script>
<style>
#sentry-feedback {
  left: 20px;
  right: auto;
  bottom: 160px;
  display: inline-block;
}
</style>
