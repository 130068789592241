/**
 * @docs Icons at https://fontawesome.com/icons
 * USAGE
 * Use the `T-Icon` component from `@/components/atoms`
 */

/**
 * @DOCS Importing icons
 * When importing from REGULAR package use import { faUserSecret } from '..-regular'
 * When importing from SOLID package use import { faUserSecret as fasUserSecret } from '..-solid'
 * When importing from LIGHT package use import { faUserSecret as falUserSecret } from '..-light'
 */

// import type { App } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTriangleExclamation,
  faChevronsUp,
  faChevronsDown,
  faRocketLaunch,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowDownAZ,
  faArrowDownLong,
  faArrowDownShortWide,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowRightToLine,
  faArrowLeftToLine,
  faArrowUp,
  faArrowUpArrowDown,
  faArrowUpAZ,
  faArrowUpLong,
  faArrowUpShortWide,
  faBarsSort,
  faBell,
  faBellOn,
  faBellSlash,
  faBullseye,
  faCalendar,
  faChartLine,
  faChartLineUp,
  faChartSimple,
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircleChevronLeft,
  faCircleChevronRight,
  faEllipsisVertical,
  faFilter,
  faFolder,
  faFolderArrowUp,
  faFolderGear,
  faFolderGrid,
  faFolderTree,
  faGaugeCircleBolt,
  faGear,
  faGrid2,
  //   faGrid,
  faGripDotsVertical,
  faHouse,
  faMagnifyingGlass,
  faMinus,
  faMonitorWaveform,
  faObjectExclude,
  faObjectIntersect,
  faObjectUnion,
  faPage,
  faPen,
  faPlus,
  faPuzzlePiece,
  faRectangleHistory,
  faRepeat,
  faRotateLeft,
  faSquareKanban,
  faSquarePollVertical,
  faSquareQuestion,
  faTableLayout,
  faTags,
  faTrashCan,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVideo,
  faWavePulse,
  faWindow,
  faX,
  faXmark,
  // faCircleXmark,
  faCopy,
  faGaugeMin,
  faPartyHorn,
  faList,
  faFlag,
  faMoneyCheckPen,
  faEye,
  faLink,
  faCloudArrowDown,
  faPaperPlane,
  faFileLines,
  faExclamation,
  faCreditCard,
  faShare,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faArrowDownLong as fasArrowDownLong,
  faArrowRight as fasArrowRight,
  faChartPieSimple as fasChartPieSimple,
  faChartTreeMap as fasChartTreeMap,
  faCheck as fasCheck,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircleInfo as fasCircleInfo,
  faGaugeMax as fasGaugeMax,
  faGaugeMin as fasGaugeMin,
  faGear as fasGear,
  faGrid as faGrid,
  faGrid2 as fasGrid2,
  //   faList as faList,
  faMagnifyingGlass as fasMagnifyingGlass,
  faPen as fasPen,
  faPlus as fasPlus,
  faWrench as fasWrench,
  faLockKeyhole as fasLockKeyhole,
  faGridRound2 as fasGridRound2,
  faArrowUpFromSquare as faArrowUpFromSquare,
  faExclamationTriangle as faExclamationTriangle,
  faFileArrowDown as faFileArrowDown,
  faCircleCheck as faCircleCheck,
  faCircleXmark as faCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";

import { faPlus as falPlus } from "@fortawesome/pro-light-svg-icons";

library.add(
  faTriangleExclamation,
  faChevronsUp,
  faChevronsDown,
  faRocketLaunch,
  faFileArrowDown,
  faExclamationTriangle,
  faArrowUpFromSquare,
  faUserPlus,
  faBell,
  faBullseye,
  faChartSimple,
  faX,
  faBarsSort,
  faSquareKanban,
  faWavePulse,
  faUserSecret,
  faGear,
  faCalendar,
  fasChevronLeft,
  fasChevronRight,
  faMonitorWaveform,
  faSquarePollVertical,
  faGaugeCircleBolt,
  faPuzzlePiece,
  faFolderTree,
  faChartLineUp,
  faUsers,
  faChevronLeft,
  faMagnifyingGlass,
  fasMagnifyingGlass,
  faPlus,
  fasWrench,
  faArrowRightFromBracket,
  faChevronRight,
  fasCheck,
  fasGear,
  fasCircleInfo,
  faRepeat,
  faXmark,
  fasPlus,
  fasPen,
  faEllipsisVertical,
  faRepeat,
  faXmark,
  faCircleChevronRight,
  faCircleChevronLeft,
  faArrowUpArrowDown,
  faArrowUpLong,
  faArrowRight,
  fasArrowDownLong,
  faChevronDown,
  faChevronUp,
  faFolder,
  faEllipsisVertical,
  faArrowDownAZ,
  faArrowUpAZ,
  faArrowDown,
  faArrowUp,
  faTrashCan,
  faPen,
  faRotateLeft,
  faCheck,
  faFolderGear,
  faFilter,
  faBellOn,
  faBellSlash,
  faFolderArrowUp,
  faArrowRight,
  fasArrowDownLong,
  faArrowDownLong,
  faArrowRightLong,
  falPlus,
  faRectangleHistory,
  faArrowLeft,
  faChartLine,
  fasChartPieSimple,
  faRectangleHistory,
  faGripDotsVertical,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faArrowRightToLine,
  faArrowLeftToLine,
  faCalendar,
  fasChartTreeMap,
  faSquareQuestion,
  faObjectIntersect,
  faObjectUnion,
  faObjectExclude,
  faPage,
  faArrowDownShortWide,
  faArrowUpShortWide,
  fasArrowRight,
  faHouse,
  faTableLayout,
  faTags,
  faVideo,
  faGrid2,
  faGrid,
  fasGrid2,
  faList,
  faWindow,
  faFolderGrid,
  faMinus,
  fasGaugeMin,
  fasGaugeMax,
  fasLockKeyhole,
  faCircleXmark,
  faCopy,
  faGaugeMin,
  fasGridRound2,
  faPartyHorn,
  faFlag,
  faCircleCheck,
  faLink,
  faMoneyCheckPen,
  faEye,
  faCloudArrowDown,
  faPaperPlane,
  faFileLines,
  faExclamation,
  faCreditCard,
  faShare,
);

export function defineFontAwesomePlugin() {}
