<template>
  <input
    :id="Context?.id"
    ref="input"
    @[modelEvent]="value = $event.target.value"
    :value="value"
    v-focus="autofocus"
    :class="[
      {
        'input-error': Context?.invalid ?? invalid,
        'border-t-0 border-x-0 rounded-none': variant === 'underline',
        'h-[22px] !rounded-[4px] !text-xs px-4': size === 'tiny',
        'h-[34px] text-sm': size === 'xs',
        'h-10 text-base': !size,
        'h-8 px-2 text-sm': size === 'sm',
        'h-12 text-base': size === 'lg',
        'select-none': readonly,
      },
      size === 'tiny' ? 'border' : 'border-2',
    ]"
    :invalid="Context?.invalid ?? invalid"
    :placeholder="placeholder"
    :readonly="readonly"
    :type="type"
    class="w-full input input-bordered focus:border-black focus:outline-none placeholder:text-gray-700"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent, ref, type PropType } from "vue";
import { useFormControl } from "./use-form-control";

export default defineComponent({
  name: "t-input",
  emits: ["update:modelValue"],
  props: {
    as: {
      type: String,
      default: "input",
    },
    readonly: {
      type: Boolean,
      default: undefined,
    },
    modelValue: {},
    modelModifiers: {
      type: Object as PropType<{ lazy: boolean }>,
      default: () => ({ lazy: false }),
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: Boolean,
    id: {
      type: String,
      required: false,
    },
    variant: {
      type: String as PropType<"bordered" | "underline">,
      default: "bordered",
      validator: (v) => ["bordered", "underline"].includes(v),
    },
    size: {
      type: String as PropType<"xs" | "sm" | "lg" | "xl" | "tiny">,
      validator(value) {
        return ["sm", "lg", "xs", "xl", "tiny"].includes(value);
      },
    },
    placeholder: {
      type: String,
      required: false,
    },
    autofocus: Boolean,
  },
  setup(props, context) {
    const Context = useFormControl();

    const value = useVModel(props);
    const input = ref<{ focus: () => void } | null>(null);

    function focus() {
      input.value?.focus();
    }

    context.expose({ focus });

    const modelEvent = props.modelModifiers.lazy ? "change" : "input";

    return {
      Context,
      value,
      input,
      focus,
      modelEvent,
    };
  },
});
// "input-primary", "input-secondary", "input-accent", "input-ghost", "input-info", "input-success", "input-warning", "input-error", input-lg, input-md, input-xs input-bordered
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
