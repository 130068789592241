<template>
  <div
    class="min-h-screen login-container backdrop-blur-3xl w-full py-16 px-4 bg-white flex"
  >
    <div class="flex-1 max-w-[1200px] mx-auto">
      <main-logo-big class="logo mb-8 ml-auto" />
      <slot />
    </div>
  </div>
</template>

<script>
import MainLogoBig from "@/components/atoms/main-logo-big.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "onboardingLayout",
  components: { MainLogoBig },
  setup() {
    useHead({
      title: "Trackian - Get started",
      script: [
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T7RLSD6R');`,
          tagPosition: "bodyOpen",
        },
        {
          src: "https://js.stripe.com/v3/",
          tagPosition: "head",
        },
      ],
    });
  },
};
</script>
<style>
.logo {
  margin-right: calc(50% - 80px);
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
}
</style>
