import { io } from "socket.io-client";

const hash = localStorage.getItem("passport");
const socket = io(import.meta.env.VITE_NOTIFICATIONS_URL, {
  query: {
    hash,
  },
});

export default socket;
