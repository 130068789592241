<template>
  <div
    v-if="!navigationIsDisabled"
    :class="isSlimMenuShown ? 'w-20' : 'w-72'"
    class="h-screen flex flex-col bg-gray-100 shadow-md shadow-gray-200 ease-linear relative"
    v-bind="$attrs"
  >
    <div class="flex flex-col flex-1 w-full overflow-hidden">
      <div></div>

      <div v-if="isSlimMenuShown === false" class="px-6 py-5">
        <main-logo></main-logo>
      </div>

      <div v-if="isSlimMenuShown === true" class="px-4 py-5 flex-shrink-0">
        <secondary-logo></secondary-logo>
      </div>

      <div class="flex-1 overflow-y-auto items-left scrollbar-style">
        <ul
          class="main-link rounded items-start transition-all ease-linear duration-300"
        >
          <li
            v-for="(navigationElement, index) in navConfig"
            :key="navigationElement.url"
            @click="openSubnavigation(navigationElement)"
            @mouseover="mouseOverNavigation(navigationElement)"
          >
            <main-link
              :arrowIcon="navigationElement.arrowIcon"
              :icon="navigationElement.icon"
              :is-selected="navigationElement.isSelected"
              :is-slim-menu-shown="isSlimMenuShown"
              :route-name="navigationElement.routeName"
              :title="navigationElement.title"
              v-bind:class="{ active: navigationElement.isSelected == true }"
              :data-qa="navigationElement.dataQa"
            />
            <div :class="isSlimMenuShown ? 'hidden' : ''">
              <span v-if="navigationElement.arrowIcon" class="mt-8">
                <inside-menu
                  v-if="
                    navigationElement.isSelected && navigationElement.hasSubMenu
                  "
                  :menu-config="navigationElement.config"
                />
              </span>
            </div>

            <side-menu
              v-if="
                navigationElement.isSelected &&
                navigationElement.hasSubMenu &&
                isSlimMenuShown
              "
              :menu-config="navigationElement.config"
              :menu-title="navigationElement.title"
              @mouseleave="closeAll()"
              class="absolute top-[112px] folder-style bg-gray-100 w-[256px] shadow-md shadow-gray-200 ease-linear"
            ></side-menu>
          </li>
          <li @click="openNotificationModal = true">
            <div
              v-if="!isSlimMenuShown"
              class="group mx-4 border-t-2 border-gray-200"
            >
              <div
                class="flex w-full px-3 py-2 my-1 font-bold group-hover:bg-blue-100 transition-all duration-500 ease-in-out items-center justify-between cursor-pointer"
              >
                <span class="flex items-center justify-between">
                  <span class="flex items-center">
                    <t-icon
                      class="text-gray-800 group-hover:text-black text-lg"
                      is="bell"
                    />
                  </span>
                  <span
                    class="text-base font-medium font-sans group-hover:text-black px-4"
                    >Notifications</span
                  >
                </span>
                <button
                  v-if="
                    notifications.filter((n) => n.status != 'read').length > 0
                  "
                  class="t-btn inline-flex items-center relative font-display border border-transparent rounded-full min-h-0 transition duration-100 focus-visible:outline-1 focus-visible:outline-offset-[4px] text-white leading-none whitespace-nowrap text-xs t-btn-primary h-4 px-1.5 text-sm !h-4 rounded-full whitespace-nowrap"
                >
                  {{ notifications.filter((n) => n.status != "read").length }}
                </button>
              </div>
            </div>

            <div v-else class="group mx-4 border-t-2 border-gray-200">
              <div
                class="flex relative w-full px-3 py-2 my-1 font-bold group-hover:bg-blue-100 transition-all duration-500 ease-in-out items-center justify-between cursor-pointer"
              >
                <span class="flex items-center">
                  <t-icon
                    class="text-gray-800 group-hover:text-black text-lg"
                    is="bell"
                  />
                </span>
                <span
                  class="w-3.5 h-3.5 rounded-full top-0 right-4 block z-30 absolute bg-blue-primary border-2 border-gray-100"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex-shrink-0 w-full pt-3">
      <div class="relative pt-3">
        <!-- BEGIN PROFILE OPTIONS -->
        <user-profile
          :is-compact="isSlimMenuShown"
          @click="isSlimMenuShown ? toggleSidebar() : null"
          @is-notification-open="toggleNotifications()"
        />
        <!-- END PROFILE OPTIONS -->
      </div>
    </div>

    <!-- BEGIN ARROW OPEN/CLOSE -->
    <button
      :class="{ move: isSlimMenuShown }"
      class="btn-rounded z-[9999] toggleUpDown absolute btn-bottom rotate Btn w-h transition-all ease-linear duration-100 bg-white shadow-md shadow rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
      v-on:click="toggleSidebar"
    >
      <t-icon
        is="chevron-right fa-2xl z-[100]"
        :class="{ rotate: isSlimMenuShown }"
        class="toggleUpDown"
      /></button
    ><!-- END ARROW OPEN/CLOSE -->
    <notifications-modal
      @close="openNotificationModal = false"
      v-model:is-open="openNotificationModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUIStore } from "@/stores/ui-store";
import { useUserStore } from "@/stores/user-store";
import { useProjectsStore } from "@/stores/projects-store";
import MainLogo from "@/components/atoms/main-logo.vue";
import SecondaryLogo from "@/components/atoms/secondary-logo.vue";
import MainLink from "./link.vue";
import { TIcon } from "@/components/atoms";
import { useNavStore } from "@/stores/nav-store";
import InsideMenu from "@/components/navigation/inside-menu.vue";
import SideMenu from "@/components/navigation/side-menu.vue";
import UserProfile from "./user-profile.vue";
import NotificationsModal from "@/components/navigation/notifications-modal.vue";
import { useQueryClient } from "vue-query";
import { useNotificationsStore } from "@/stores/notification-store";

export default {
  name: "NavigationIndex",
  components: {
    NotificationsModal,
    SideMenu,
    InsideMenu,
    TIcon,
    SecondaryLogo,
    MainLogo,
    MainLink,
    UserProfile,
  },
  setup() {
    const queryClient = useQueryClient();
    return {
      queryClient,
    };
  },
  data() {
    return {
      openNotificationModal: false,
      navConfig: [
        {
          routeName: "",
          title: "Overview",
          icon: "monitor-waveform",
          arrowIcon: "chevron-right",
          isSelected: false,
          hasSubMenu: true,
          dataQa: "monitoringNav",
          config: [
            {
              url: "/monitoring/all-projects",
              routeName: "monitoring_all_projects",
              title: "All channels",
              logo: "all-projects",
              color: "google-analytics",
              dataQa: "allChannelsMonitoringNav",
            },
            {
              url: "/monitoring/2",
              title: "Facebook Ads",
              routeName: "monitoring_platform",
              logo: "facebook",
              color: "facebook-ads",
              dataQa: "facebookMonitoringNav",
            },
            {
              url: "/monitoring/3",
              title: "Google Search Console",
              logo: "google-search-console",
              routeName: "monitoring_platform",
              color: "google-search-console",
              dataQa: "googleSeachConsoleMonitoringNav",
            },
            {
              url: "/monitoring/5",
              title: "Google Ads",
              logo: "google-ads",
              routeName: "monitoring_platform",
              color: "google-ads",
              dataQa: "googleAdsMonitoringNav",
            },
          ],
        },
        {
          routeName: "",
          title: "Analysis",
          icon: "chart-line-up",
          arrowIcon: "chevron-right",
          isSelected: false,
          hasSubMenu: true,
          dataQa: "analysisMenu",
          config: [
            {
              url: "/all-channels-analysis",
              title: "All channels",
              logo: "all-projects",
              color: "google-analytics",
              routeName: "all_channels_analysis",
              platform: "",
              dataQa: "allChannelsAnalysisSubMenu",
            },
            {
              url: "/performance-analysis/facebook-ads",
              title: "Facebook Ads",
              logo: "facebook",
              color: "facebook-ads",
              routeName: "performance_analysis_facebook_ads_project",
              platform: "FACEBOOK_ADS",
              dataQa: "FBAdsAnalysisSubMenu",
            },
            {
              url: "/performance-analysis/google-search-console",
              title: "Google Search Console",
              logo: "google-search-console",
              color: "google-search-console",
              routeName: "performance_analysis_google_search_console_project",
              platform: "GOOGLE_SEARCH_CONSOLE",
              dataQa: "GSCAnalysisSubMenu",
            },
            {
              url: "/performance-analysis/google-ads",
              title: "Google Ads",
              logo: "google-ads",
              color: "google-ads",
              routeName: "performance_analysis_google_ads_project",
              platform: "GOOGLE_ADS",
              dataQa: "GAdsAnalysisSubMenu",
            },
          ],
        },
        {
          routeName: "projects",
          title: "Projects",
          icon: "folder-tree",
          hasSubMenu: false,
          isSelected: false,
        },
        {
          routeName: "settings_user_profile",
          title: "Settings",
          icon: "gear",
          hasSubMenu: false,
          isSelected: false,
        },
      ],
    };
  },
  created() {
    this.fetchUserOnce();

    for (let i = 0; i < this.navConfig.length; i++) {
      this.navConfig[i].isSelected = false;
      if (this.navConfig[i].routeName === this.currentRouteName) {
        this.navConfig[i].isSelected = true;
      }

      if (this.navConfig[i].hasSubMenu) {
        for (let j = 0; j < this.navConfig[i].config.length; j++) {
          if (this.navConfig[i].config[j].routeName === this.currentRouteName) {
            this.navConfig[i].isSelected = true;
          }
        }
      }
    }
  },
  computed: {
    ...mapWritableState(useNavStore, ["monitorConfig", "analysisConfig"]),
    ...mapState(useUserStore, ["user"]),
    ...mapState(useProjectsStore, ["projects", "platforms"]),
    ...mapState(useNotificationsStore, ["notifications"]),
    ...mapWritableState(useUIStore, [
      "isSubnavOpen",
      "isOpenMonitoring",
      "isSlimMenuShown",
      "navigationIsDisabled",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useUserStore, {
      reallyLogout: "logout",
      fetchUserOnce: "reload",
    }),
    async logout(e) {
      e.preventDefault();
      try {
        await this.reallyLogout();

        await this.$router.push({ name: "login" });
        this.queryClient.removeQueries();
      } catch (error) {
        console.log(error?.response || error);
      }
    },
    toggleSidebar() {
      this.isSlimMenuShown = !this.isSlimMenuShown;
      this.closeAll();
    },
    openSubnavigation(navigationElement) {
      this.navConfig.forEach((element) => (element.isSelected = false));
      navigationElement.isSelected = true;
    },
    closeAll() {
      this.navConfig.forEach((element) => (element.isSelected = false));
    },
    mouseOverNavigation(navigationElement) {
      if (this.isSlimMenuShown) {
        this.closeAll();
        navigationElement.isSelected = !!navigationElement.hasSubMenu;
        return;
      }
    },
  },
};
</script>

<style lang="scss">
.btn-rounded {
  @apply rounded-full;
}

.folder-nav {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  background: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.folder-style {
  margin-left: 75px;
  overflow: hidden;
  position: absolute;
  left: 5px;
}

div::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  border-color: #2d3748;
}

div::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: white;
}

.toggleUpDown.rotate {
  transform: rotate(180deg);
}

.toggleUpDown.move {
  left: 63px;
}

.btn-bottom {
  bottom: 80px;
  left: 270px;
}

.w-h {
  height: 36px;
  width: 36px;
}

.top-135 {
  top: 135px;
}

.top-185 {
  top: 185px;
}
</style>
