<template>
  <span
    :class="{
      'absolute top-8 right-8': !!static === false,
    }"
  >
    <t-button
      color="dark"
      square
      size="sm"
      link
      @click="Context.close"
      aria-label="Close dialog"
    >
      <t-icon class="text-lg" is="xmark" />
    </t-button>
  </span>
</template>

<script lang="ts" setup>
import { TButton, TIcon } from "@/components/atoms";
import { useModal } from "./use-modal";

const props = defineProps({
  /**
   * By default the button will be positioned at the top right corner
   * Use this prop if you want to manually control its position
   */
  static: Boolean,
});

const Context = useModal();
</script>
