import { defineToastPlugin } from "./plugins/toast.plugin";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { useUserStore } from "./stores/user-store";
import { defineDefaultAxiosPlugin } from "./plugins/axios-default.plugin";
import { defineVueQueryPlugin } from "./plugins/vue-query.plugin";
import { defineFontAwesomePlugin } from "./plugins/fontawesome.plugin";
import { VFocus } from "./directives/focus-directive";
import * as Sentry from "@sentry/vue";
import { createHead } from "@unhead/vue";

import "./styles/index.css";

async function bootstrap() {
  const pinia = createPinia();
  const app = createApp(App).use(pinia);
  const head = createHead();
  app.use(head);

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    enabled: true,
    logErrors: import.meta.env.DEV,
    release: import.meta.env.VITE_BITBUCKET_COMMIT,
    integrations: [
      ...!process.env.NODE_ENV == 'development' ? [new Sentry.Feedback({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "light",
        autoInject: true,
        showBranding: false,
      })] : [],
    ],
  });

  app.config.globalProperties["$sentry"] = Sentry;

  defineVueQueryPlugin(app);

  app.directive("focus", VFocus);

  defineFontAwesomePlugin(app);

  defineDefaultAxiosPlugin(app);

  // Order matters here. We want to setup axios + pinia so that we can fetch
  // the current user, then initialize the router so that its hooks redirect him
  // if he's not allowed to visit this route
  const user = useUserStore();
  await user.get();

  app.use(router);

  defineToastPlugin(app);

  app.mount("#app");
}

bootstrap();
