<template>
  <div class = "avatar">
  <div
      class="rounded-full"
  >

    <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-avatar",
  props: {
    src: {
      required:false,
    },
  },
  methods: {
  },
};
</script>

<style></style>