import * as Sentry from "@sentry/vue";
import axios from "axios";
import type { App } from "vue";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "vue-query";

/**
 * @description https://tanstack.com/query/latest/docs/vue/overview
 */
export function defineVueQueryPlugin (app: App) {
  /**
   * Global error reporting implementations for Mutations and Queries.
   * We do not care about Axios errors here, because they are reported by the Axios instance.
   * We care about the other errors that occur, like type/reference errors
   */
  const mutationCache = new MutationCache({
    onError (error) {
      if (axios.isAxiosError(error)) {
        return;
      }

      Sentry.captureException(error);
    },
  });

  const queryCache = new QueryCache({
    onError (error) {
      if (axios.isAxiosError(error)) {
        return;
      }

      Sentry.captureException(error);
    },
  });

  const client = new QueryClient({
    mutationCache,
    queryCache,
    defaultOptions: {
      queries: {
        // Don't want these by default, they can be overwritten on a per-query basis
        // Be careful with these in places where forms are using the response data, because
        // refetching them might cause the user to lose his changes
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        // We use an axios plugin for retries
        retry: false,
        // Disables cache for all queries by default
        staleTime: 0,
      },
    },
  });

  const options: VueQueryPluginOptions = {
    queryClient: client,
  };

  app.use(VueQueryPlugin, options);
}
