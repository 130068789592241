<template>
  <component
    class="t-btn inline-flex items-center relative px-6 font-display border border-transparent rounded-lg min-h-0 transition duration-100 focus-visible:outline-1 focus-visible:outline-offset-[4px] text-white leading-none whitespace-nowrap"
    :class="{
      [`t-btn-${color}`]: true,
      't-btn-outline': outline,
      't-btn-link': link,
      'pointer-events-none select-none': isDisabled,
      'rounded-full px-0': fab,
      'px-0': square,
      'h-10': size === 'default',
      'w-10': size === 'default' && (fab || square),
      'h-12': size === 'lg',
      'w-12': size === 'lg' && (fab || square),
      'h-9 px-4': size === 'sm',
      'h-8 px-4': size === 'xs',
      'w-8': size === 'sm' && (fab || square),
      uppercase: textTransform === 'uppercase',
      capitalize: textTransform === 'capitalize',
      [sizeClass]: true,
    }"
    :is="as"
    v-bind="$attrs"
    :type="as === 'button' ? type : ''"
    :disabled="isDisabled"
  >
    <span class="mr-2 leading-none" v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </span>

    <span
      :class="{
        truncate: truncate,
      }"
    >
      <slot></slot>
    </span>

    <span class="ml-2 leading-none" v-if="$slots.append">
      <slot name="append"></slot>
    </span>

    <span
      v-if="disabled || loading"
      class="absolute -inset-[2px] flex justify-center items-center opacity-60 rounded-[inherit] bg-white"
    >
      <t-spinner
        v-if="loading"
        :size="['sm', 'xs'].includes(size) ? 'xs' : 'sm'"
      />
    </span>
  </component>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import TSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "t-button",
  components: {
    TSpinner,
  },
  props: {
    as: {
      type: String,
      default: "button",
    },

    textTransform: {
      type: String as PropType<"uppercase" | "capitalize" | "none">,
      default: "none",
      validator(value: string) {
        return ["uppercase", "capitalize", "none"].includes(value);
      },
    },

    // Displays the button with a border and no background
    outline: Boolean,

    // Displays the button with no background or border
    link: Boolean,

    // Displays the button as a fully-rounded circle
    fab: Boolean,

    // Displays the button as a square
    square: Boolean,

    truncate: Boolean,

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    color: {
      type: String,
      validator(value) {
        return [
          "primary",
          "secondary",
          "info",
          "success",
          "warning",
          "error",
          "light",
          "dark",
          "gray",
          "gray-200",
          "white",
          "ghost",
          "blue-100",
        ].includes(value);
      },
      default: "primary",
    },

    size: {
      type: String as PropType<"xs" | "sm" | "lg" | "default">,
      default: "default",
      validator(value) {
        return ["xs", "sm", "lg", "default"].includes(value);
      },
    },
  },
  setup(props, context) {
    const isDisabled = computed(() => {
      return props.disabled || props.loading;
    });

    const sizeClass = computed(() => {
      const { size, textTransform } = props;
      const isUppercase = textTransform === "uppercase";

      switch (size) {
        // Font sizes vary based on the size of the button and the text casing
        case "lg":
          return isUppercase ? "text-base" : "text-1xl";
        case "sm":
          return isUppercase ? "text-sm" : "text-base";
        case "xs":
          return "text-sm";
        default:
          return isUppercase ? "text-sm" : "text-base";
      }
    });

    return {
      isDisabled,
      sizeClass,
    };
  },
});
</script>

<style lang="scss">
.t-btn {
  @apply font-semibold justify-center;

  &.t-btn-primary {
    @apply bg-blue-primary outline-blue-primary hover:bg-[#0159FE];

    &.t-btn-secondary {
      @apply border-blue-primary text-blue-primary hover:text-white hover:bg-[#0159FE];
    }

    &.t-btn-outline {
      @apply border-blue-primary text-blue-primary hover:text-white hover:bg-[#0159FE];
    }

    &.t-btn-link {
      @apply text-primary;
    }
  }

  &.t-btn-white {
    @apply bg-white outline-gray-200 text-black border-gray-200 hover:border-black;

    &.t-btn-outline {
      @apply border-white hover:text-black hover:bg-white;
    }

    &.t-btn-link {
      @apply text-white;
    }
  }

  &.t-btn-gray {
    @apply bg-white text-gray-400 hover:bg-none hover:border-transparent outline-none;

    &.t-btn-link {
      @apply text-gray-400 hover:border-transparent;
    }

    &.t-btn-outline {
      @apply border-gray-400 text-gray-400 hover:bg-gray-150 hover:border-gray-150;
    }
  }

  &.t-btn-gray-200 {
    @apply bg-white text-gray-400 hover:bg-gray-200 border-gray-200 hover:border-gray-200 outline-gray-200;

    &.t-btn-link {
      @apply text-gray-400 hover:border-transparent;
    }

    &.t-btn-outline {
      @apply border-gray-200 text-gray-400 hover:bg-gray-200 hover:border-gray-200;
    }
  }

  &.t-btn-secondary {
    @apply bg-[#FCFCFC] border-[#E0E0E0] hover:border-[#2F80ED] text-[#4F4F4F] hover:text-[#2F80ED];

    &.t-btn-link {
      @apply text-[#4F4F4F];
    }
  }

  &.t-btn-info {
    @apply bg-info outline-info hover:bg-[#0155F4];

    &.t-btn-outline {
      @apply border-info text-info hover:text-white hover:bg-info;
    }

    &.t-btn-link {
      @apply text-info;
    }
  }

  &.t-btn-success {
    @apply bg-success outline-success hover:bg-[#009963];

    &.t-btn-outline {
      @apply border-success text-success hover:text-white hover:bg-success;
    }

    &.t-btn-link {
      @apply text-success;
    }
  }

  &.t-btn-warning {
    @apply bg-warning text-neutral outline-warning hover:bg-[#FAA805];

    &.t-btn-outline {
      @apply border-warning text-warning hover:text-white hover:bg-warning;
    }

    &.t-btn-link {
      @apply text-warning;
    }
  }

  &.t-btn-error {
    @apply bg-primary outline-primary hover:bg-[#E90C42];

    &.t-btn-outline {
      @apply border-error text-error hover:text-white hover:bg-error;
    }

    &.t-btn-link {
      @apply text-error;
    }
  }

  &.t-btn-light {
    @apply bg-[#FCFCFC] border-[#E0E0E0] hover:bg-gray-150 hover:border-gray-150 text-[#4F4F4F] hover:text-gray-400;

    &.t-btn-link {
      @apply text-gray-400 hover:border-transparent;
    }

    &.t-btn-outline {
      @apply border-gray-200 text-gray-400 hover:bg-gray-100 hover:border-gray-100;
    }
  }

  &.t-btn-dark {
    @apply bg-[#1A1A1A] outline-[#1A1A1A] hover:bg-[#262626];

    &.t-btn-link {
      @apply text-neutral bg-transparent;
    }

    &.t-btn-outline {
      @apply text-neutral border-neutral hover:text-white hover:bg-neutral;
    }
  }

  &.t-btn-link {
    @apply md:text-[#0150E5] lg:text-[#0150E5] xl:text-[#0150E5] 2xl:text-[#0150E5] md:hover:underline lg:hover:underline xl:hover:underline 2xl:hover:underline md:underline-offset-2 lg:underline-offset-2 xl:underline-offset-2 2xl:underline-offset-2 md:border-transparent
    lg:border-transparent xl:border-transparent 2xl:border-transparent md:hover:bg-transparent lg:hover:bg-transparent xl:hover:bg-transparent 2xl:hover:bg-transparent hover:bg-[#0159FE] bg-transparent border-blue-primary text-blue-primary hover:text-white md:hover:text-[#0150E5] lg:hover:text-[#0150E5] xl:hover:text-[#0150E5] 2xl:hover:text-[#0150E5] #{!important};
  }

  &.t-btn-link-error {
    @apply text-white md:text-error lg:text-error xl:text-error 2xl:text-error md:hover:underline lg:hover:underline xl:hover:underline 2xl:hover:underline md:underline-offset-2 lg:underline-offset-2 xl:underline-offset-2 2xl:underline-offset-2 md:bg-transparent lg:bg-transparent xl:bg-transparent 2xl:bg-transparent md:border-transparent lg:border-transparent xl:border-transparent 2xl:border-transparent
    bg-primary outline-primary hover:bg-[#E90C42] md:hover:bg-transparent lg:hover:bg-transparent xl:hover:bg-transparent 2xl:hover:bg-transparent #{!important};
  }

  &.t-btn-ghost {
    @apply bg-none text-[#0150E5] hover:bg-blue-100;
  }

  &.t-btn-outline {
    @apply bg-transparent border-blue-primary text-blue-primary hover:text-white hover:bg-[#0159FE];
  }

  &.t-btn-blue-100 {
    @apply bg-blue-100 outline-blue-100 border-gray-200 hover:bg-blue-200 text-black;
  }
}
</style>
