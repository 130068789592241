import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import weekOfYear from "dayjs/plugin/weekOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import minMax from "dayjs/plugin/minMax";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(minMax);
dayjs.extend(duration);

dayjs.updateLocale("en", {
  /**
   * This plugin allows the usage of dayjs(date).format('dddd') to display
   * the weekday's name
   */
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  // week starts of monday (it's hardcoded in backend queries)
  weekStart: 1,
});

export function useDayjs() {
  return dayjs;
}

export { dayjs };
