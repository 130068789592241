import { isAxiosError } from "@/modules/common/utils";
import { defineStore } from "pinia";
import { useUIStore } from "@/stores/ui-store";
import { useRouter } from "vue-router";
import SharedAccessService from "@/services/sharedAccess.service";

type AuthSession<IsAuthenticated = true> = {
  user: User;
  member: IsAuthenticated extends true ? Member : null;
  intercomHash: string;
};

export type MemberRole = {
  id: number;
  name: string;
  label: string;
};

export type Company = {
  id: number;
  name: string;
  logoSrc: string;
  remainingDailyChannelGroupResyncs: number;
};

type Member = {
  id: number;
  companyId: number;
  userId: number;
  memberRoleId: number;
  company: Company;
  role: MemberRole;
  isActivated: boolean;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  id: number;
  email: string;
  avatarSrc: string;
  firstName: string;
  lastName: string;
  employees: any[];
  updatedAt: string;
  createdAt: string;
  defaultCompanyId?: number;
};

type RegisterDto = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  hash: string;
};

type LoginDto = {
  email: string;
  password: string;
  rememberMe: boolean;
  hash?: string;
};

export const useUserStore = defineStore({
  id: "user",

  state: () => ({
    user: null as User | null,
    member: null as Member | null,
    company: null as Company | null,
    intercomHash: null as string | null,
  }),

  getters: {
    isGuest(): boolean {
      return this.user === null;
    },

    isAuthenticatedAsUser(): boolean {
      return this.user !== null;
    },

    isAuthenticatedAsMember(): boolean {
      return this.member !== null;
    },
  },

  actions: {
    async authenticateAsMember(companyId: number) {
      const { data } = await this.$axios.post<AuthSession>("/auth/member", {
        companyId,
      });

      this.user = data.user;
      this.member = data.member;
      this.company = data.member.company;
      this.hash = data.hash;
      this.intercomHash = data.intercomHash;

      localStorage.setItem("passport", data.hash);
      localStorage.setItem("userId", data.user.id);
      localStorage.setItem("memberId", data.member.id);
      localStorage.setItem("companyId", data.member.companyId);

      return {
        user: data.user,
        member: data.member,
        company: data.member.company,
        hash: data.hash,
      };
    },

    async reload() {
      await this.get();
    },

    async get() {
      const uiStore = useUIStore();
      const router = useRouter();
      try {
        const { data } = await this.$axios.get<AuthSession>("/auth");
        this.user = data.user;
        this.member = data.member;
        this.intercomHash = data.intercomHash;
        this.company = data.member ? data.member.company : null;

        if (data.member.role.name == "OBSERVER") {
          uiStore.disableNavigation();

          const accesses = (await this.$axios.get(`/shared-access/any`)).data;
          if (accesses.length == 1) {
            const url = SharedAccessService.getRoute(accesses[0]);
            router.push(url);
          }
        }
      } catch (e) {
        console.log("Not logged in");
      }
    },

    async authenticateAsUser(loginDto: LoginDto) {
      const { data } = await this.$axios.post<AuthSession>("/auth", loginDto);

      // If the user has set a default company the backend will authenticate him
      // in it and this endpoint will return the company as well.
      const { user, member } = data;

      this.user = user;
      this.member = member;
      this.company = member?.company ?? null;

      return {
        user,
        member,
        company: member?.company ?? null,
      };
    },

    async register(registerDto: RegisterDto) {
      try {
        const { data, status } = await this.$axios.post("/auth/register", {
          email: registerDto.email,
          hash: registerDto.hash,
          firstName: registerDto.firstName,
          lastName: registerDto.lastName,
          password: registerDto.password,
        });

        this.user = data;

        return {
          user: data,
          isError: false,
          message: "Success",
        };
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.status === 400) {
            return {
              user: {},
              isError: true,
              message: error.response.data.error.message,
            };
          }

          return {
            user: {},
            isError: true,
            message: "Something went wrong.",
          };
        }
      }
    },

    async logout() {
      await this.$axios.post("/auth/logout");

      this.user = null;
      this.member = null;
      this.company = null;
    },
  },
});
