import { defineStore } from "pinia";
import { useDefaultAxios } from "@/plugins/axios-default.plugin";

const axios = useDefaultAxios();

export const useBillingStore = defineStore({
  id: "billing",
  state: () => ({
    loaded: false,
    _loading: false,
    _settings: {
      addons: [],
      nextPlan: {},
      prevPlan: {},
      portalUrl: "",
      price: {},
      projects: {},
      status: "",
      subscription: {},
      current: {},
    },
    plans: {
      standard: "Standard",
      pro: "Pro",
      custom: "Custom",
    },
  }),
  getters: {
    settings: (state) => state._settings,
    planNames: (state) => Object.values(state.plans),
  },
  actions: {
    async load() {
      if (this.loaded || this._loading) return;

      this._loading = true;

      this._settings = (await axios.get("/billing/settings")).data;

      this._loading = false;
      this.loaded = true;
    },
  },
});
