<template>
  <div class="t-tags-input">
    <vue-tags-input
      v-model="tag"
      :tags="internalTags"
      :placeholder="placeholder"
      v-show="autocompleteItems"
      @tags-changed="onTagsChange"
      @before-adding-tag="onBeforeAddTag"
    >
      <slot />

      <template v-if="$slots['tag-center']" #tag-center="slotAttrs">
        <slot name="tag-center" v-bind="slotAttrs" />
      </template>
    </vue-tags-input>
    <TErrorMessage v-if="error" class="mt-2">{{ error }}</TErrorMessage>
  </div>
</template>

<script>
import VueTagsInput from "@sipec/vue3-tags-input";
import { useVuelidate } from "@vuelidate/core";
import { TErrorMessage } from "@/components/atoms/form";

export default {
  name: "t-tags",
  components: {
    VueTagsInput,
    TErrorMessage,
  },
  emits: ["update:tags"],
  props: {
    tags: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Press enter to add email",
    },
    autocompleteItems: {
      type: Array,
      default: () => [],
      validation: useVuelidate(),
    },
    validate: {
      type: Function,
    },
  },
  computed: {
    internalTags() {
      return (
        this.tags?.map((tag) => ({
          text: tag,
        })) ?? []
      );
    },
  },
  data() {
    return {
      tag: "",
      error: "",
    };
  },
  methods: {
    onTagsChange(event) {
      const tags = event.map((tag) => tag.text);

      this.$emit("update:tags", tags);
    },
    async onBeforeAddTag(arg) {
      const { addTag, tag } = arg;

      const error = await this.validate?.(tag.text);

      if (typeof error === "string") {
        this.error = error;
        return;
      } else {
        addTag();
        this.error = "";
      }
    },
  },
};
</script>

<style lang="css">
/**
 * The additional prefix class is added for css selector weight
 */
.t-tags-input .vue-tags-input {
  max-width: 100% !important;
}

.t-tags-input .vue-tags-input .ti-tag {
  @apply text-xs;
}

.t-tags-input .vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #000000;
}

.t-tags-input .vue-tags-input .ti-input {
  padding: 4px 10px;
  border-radius: 8px;
  min-height: 48px;
  transition: border-bottom 200ms ease;
}

.t-tags-input .vue-tags-input.ti-focus .ti-input {
  border: 1px solid #000000;
  border-radius: 8px;
}

.t-tags-input .vue-tags-input ::-webkit-input-placeholder {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.t-tags-input .vue-tags-input ::-moz-placeholder {
  color: #ffffff;
}

.t-tags-input .vue-tags-input :-ms-input-placeholder {
  color: #000000;
}

.t-tags-input .vue-tags-input :-moz-placeholder {
  color: #000000;
}

.t-tags-input .vue-tags-input .ti-tag {
  position: relative;
  background: #f9f9fc;
  color: #000000;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  gap: 8px;
  border: 1px solid #d1d5db;
  border-radius: 20px;
}
.t-tags-input .vue-tags-input .ti-tag.ti-invalid {
  background-color: transparent;
}
.t-tags-input .vue-tags-input .ti-input.ti-invalid {
  border-color: #da1e28;
}
/* if the user input is invalid, the input color should be red */
.t-tags-input .vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #da1e28;
}
.t-tags-input .vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 20px;
  margin-right: 4px;
  width: 500px;
  min-height: 48px;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.t-tags-input .vue-tags-input .ti-duplicate span,
.t-tags-input .vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}
</style>
