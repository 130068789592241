export default {
  platformIdToProjectTabName: {
    2: "facebook_ads",
    3: "google_search_console",
    5: "google_ads",
  },

  getRoute(access): string {
    let route = "";

    switch (access.params.location) {
      case "Analysis": {
        route +=
          access.platformId == 4
            ? `/all-channels-analysis`
            : `/performance-analysis`;

        switch (access.platformId) {
          case 2: {
            route += `/facebook-ads/${access.projectId}`;
            break;
          }
          case 3: {
            route += `/google-search-console/${access.projectId}`;
            break;
          }
          case 4: {
            route += `/${access.projectId}`;
            break;
          }
          case 5: {
            route += `/google-ads/${access.projectId}`;
            break;
          }
        }
        break;
      }
      case "Monitoring": {
        route += `/monitoring`;

        switch (access.platformId) {
          case 2: {
            route += `/2`;
            break;
          }
          case 3: {
            route += `/3`;
            break;
          }
          case 4: {
            route += `/all-projects`;
            break;
          }
          case 5: {
            route += `/5`;
            break;
          }
        }

        if (access.projectId) {
          route += `/edit/${access.projectId}`;
        }

        break;
      }
    }

    return route;
  },
};
