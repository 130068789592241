<template>
  <component
    class="font-display text-neutral"
    :is="as"
    :class="{
      'text-6xl': size === '6xl',
      'text-5xl': size === '5xl',
      'text-4xl': size === '4xl',
      'text-3xl': size === '3xl',
      'text-2xl': size === '2xl',
      'text-xl': size === 'xl',
      'text-base': size === 'base',
      'font-bold': fontWeight === 'bold',
      'font-medium': fontWeight === 'medium',
      'font-semibold': fontWeight === 'semibold',
      'font-normal': fontWeight === 'normal',
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "T-Heading",
  props: {
    as: {
      type: String,
      default: "h2",
    },
    fontWeight: {
      type: String as PropType<"normal" | "medium" | "semibold" | "bold">,
      validate: (v) => ["normal", "medium", "semibold", "bold"].includes(v),
      default: "bold",
    },
    size: {
      type: String,
      validator: (value) => {
        return ["6xl", "5xl", "4xl", "3xl", "2xl", "xl", "base"].includes(
          value,
        );
      },
    },
  },
});
</script>
