<template>
  <TransitionRoot as="template" appear :show="isOpen">
    <Dialog @close="close" :unmount="true">
      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25 z-[100]"></div>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto z-[100]">
        <div
          class="flex min-h-full items-center"
          :class="{
            'p-4 items-center': !!fullscreen === false,
            'justify-center': align === 'center',
            'justify-end': align === 'right',
            'justify-start': align === 'left',
          }"
        >
          <TransitionChild
            @after-leave="$emit('close')"
            @after-enter="$emit('open')"
            as="template"
            enter="duration-200 ease-in"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-out"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogPanel
              v-bind="$attrs"
              class="relative transform bg-white text-left align-middle shadow-md"
              :class="{
                'rounded-lg': !!fullscreen === false,
              }"
            >
              <slot :close="close" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { computed, provide, reactive } from "vue";
import { MODAL_CONTEXT } from "./use-modal";

const emit = defineEmits(["update:isOpen", "close", "open"]);
const props = defineProps({
  fullscreen: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: "center",
    validator: v => ["center", "left", "right"].includes(v),
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

function open () {
  emit("update:isOpen", true);
}

function close () {
  emit("update:isOpen", false);
}

const Context = reactive({
  isOpen: computed(() => props.isOpen),
  open,
  close,
});

provide(MODAL_CONTEXT, Context);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
