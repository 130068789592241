<script lang="ts" setup>
import { TSpinner } from "@/components/atoms";
import { computed, useAttrs } from "vue";

const $attrs = useAttrs();
const attrs = computed(() => {
  const { class: classes, style, ...rest } = $attrs;

  return rest;
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div
    class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10"
    :class="$attrs.class"
    :style="$attrs.style"
  >
    <t-spinner v-bind="attrs" />
  </div>
</template>
