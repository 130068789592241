<script lang="ts">
import {
  TIcon,
  TModal,
  TModalContent,
  TModalCloseButton,
  TModalTitle,
} from "@/components/atoms";
import { useToast } from "vue-toastification";
import NotificationMenuItem from "@/components/navigation/notification-menu-item.vue";
import { useNotificationsStore } from "@/stores/notification-store";
import { defineComponent } from "vue";
import { mapState } from "pinia";

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TIcon,
    TModal,
    TModalContent,
    TModalCloseButton,
    TModalTitle,
    NotificationMenuItem,
  },
  emits: ["close"],
  setup() {
    const toast = useToast();
    const notificationsStore = useNotificationsStore();

    // **only return the whole store** instead of destructuring
    return { notificationsStore, toast };
  },
  methods: {
    markAllAsRead() {
      this.notificationsStore.markAllAsRead();
    },
    close() {
      this.$emit("close");
      this.markAllAsRead();
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (isOpen == false) {
          this.close();
        }
      },
    },
  },
  computed: {
    notifications() {
      return this.notificationsStore.notifications;
    },
    ...mapState(useNotificationsStore, ["notifications"]),
  },
});
</script>

<template>
  <t-modal
    @update:isOpen="close"
    :is-open="isOpen"
    class="w-[580px] min-h-screen"
    fullscreen
    align="left"
  >
    <t-modal-content>
      <header
        class="flex items-center justify-between border-b border-gray-200 pb-4 mb-2"
      >
        <div class="flex items-center">
          <t-icon is="bell" class="mr-4 text-xl" />
          <t-modal-title size="2xl" fontWeight="semibold">
            Notifications
          </t-modal-title>
          <!--          <router-link :to="{name:'events_dashboard'}"-->
          <!--                       class="t-btn inline-flex items-center relative px-6 font-display border border-transparent rounded-lg min-h-0 transition duration-100 focus-visible:outline-1 focus-visible:outline-offset-[4px] text-white leading-none whitespace-nowrap t-btn-primary t-btn-link h-10 text-base"-->
          <!--          >-->
          <!--            Go to dashboard-->
          <!--          </router-link>-->
        </div>
        <t-modal-close-button @click="close" static />
      </header>
      <div>
        <notification-menu-item
          :title="notification.title"
          :notification="notification.description"
          :status="notification.status"
          :key="notification.title"
          v-for="notification in notifications"
        />
      </div>
    </t-modal-content>
  </t-modal>
</template>
