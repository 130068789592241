<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import { useUserStore, type Company, type User } from "@/stores/user-store";
import {
  TOverlaySpinner,
  TAvatar,
  TPopover,
  TInput,
  TIcon,
} from "@/components/atoms";
import { useCompaniesQuery } from "@/views/auth/use-companies-query";
import { computed, ref, useAttrs } from "vue";
import { useAuthenticateAsMemberMutation } from "@/views/auth/use-authenticate-as-member-mutation";
import { useToast } from "vue-toastification";
import DefaultUserAvatar from "@/components/default-user-avatar.vue";
import LogoutUser from "@/components/navigation/logout.vue";
import { useUIStore } from "../../stores/ui-store";
import OnlineStatus from "@/modules/notifications/views/online-status.vue";
import BillingInfoTooltip from "@/components/navigation/billing-info-tooltip.vue";

const props = defineProps({
  isCompact: Boolean,
});
const UIStore = useUIStore();
const isSubnavOpen = UIStore.isSubnavOpen;
const isNotifications = UIStore.isNotificationOpen;
const userStore = useUserStore();
const user = userStore.user as User;
const authenticatedCompany = userStore.company as Company;
const toast = useToast();
const attrs = useAttrs();
const inputRef = ref();
const isOpen = ref(false);
const authenticateMutation = useAuthenticateAsMemberMutation();

function onSelect(company: Company) {
  // Selected the company he's already logged in with
  if ((userStore.company as Company).id === company.id) {
    isOpen.value = false;

    return;
  }

  authenticateMutation.mutate(company.id, {
    onError: (error) => {
      toast.error(
        error?.response?.data?.error?.message ?? "Something went wrong.",
      );
    },
    onSuccess: () => {
      toast.success(
        `Successfully logged in with company ${company.name}. Reloading page...`,
      );
      isOpen.value = false;
      setTimeout(() => {
        location.reload();
      }, 2000);
    },
  });
}

const searchQuery = ref("");

const {
  data: companies,
  error,
  isFetching: isLoading,
} = useCompaniesQuery(
  computed(() => {
    return {
      searchQuery: searchQuery.value,
      take: 20,
      reverse: false,
    };
  }),
  {
    enabled: isOpen,
  },
);

const emit = defineEmits<{
  (e: "isNotificationOpen"): void;
}>();

function toggleNotification() {
  isOpen.value = false;
  emit("isNotificationOpen");
}
</script>
<template>
  <billing-info-tooltip />
  <t-popover
    :disabled="isCompact"
    :initial-focus-ref="inputRef"
    :is-open="isOpen"
    :offset="0"
    :teleport="false"
    class="!shadow-none !border-b-0 !border-x-0 !rounded-b-none !p-2 !pt-0"
    placement="top"
    root-class="w-full"
    @update:is-open="isCompact ? null : (isOpen = $event)"
  >
    <template #reference>
      <div
        class="justify-start flex pb-6 pt-4 mx-4 z-20 cursor-pointer"
        v-bind="attrs"
      >
        <span class="mr-3">
          <t-avatar class="z-0 w-12 h-12 ease-linear item-center rounded-full">
            <span v-if="user.avatarSrc != null">
              <img :src="user.avatarSrc" />
              <img />
            </span>
            <span v-if="user.avatarSrc == null">
              <default-user-avatar />
            </span>
          </t-avatar>
          <!--          <OnlineStatus/>-->
        </span>

        <div>
          <p
            :class="isCompact === true ? 'hidden' : ''"
            class="ease-linear font-semibold font-sans text-base"
          >
            {{ user.firstName }} {{ user.lastName }}
          </p>

          <span
            :class="isCompact === true ? 'hidden' : ''"
            class="text-sm font-medium text-gray-800"
          >
            {{ authenticatedCompany.name }}
          </span>
        </div>
      </div>
    </template>
    <div>
      <div class="relative mb-1">
        <span
          class="flex items-center justify-end absolute inset-y-0 left-0 w-7 pl-2"
        >
          <t-icon
            is="magnifying-glass"
            class="text-gray-700 text-[15px]"
            solid
          />
        </span>

        <t-input
          ref="inputRef"
          v-model="searchQuery"
          class="pl-10 font-medium text-sm text-gray-700"
          placeholder="Company name"
          variant="underline"
        />
      </div>

      <div class="relative h-[180px] overflow-y-auto text-sm">
        <t-overlay-spinner
          v-if="authenticateMutation.isLoading.value || isLoading"
        />

        <template v-if="!isLoading">
          <div v-if="error" class="font-bold text-center">
            Something went wrong
          </div>

          <div v-else-if="companies?.nodes.length === 0">
            Did not find any companies.
          </div>
        </template>

        <div v-if="companies?.nodes">
          <button
            v-for="company in companies.nodes"
            :key="company.id"
            :disabled="
              company.id === authenticatedCompany.id ||
              authenticateMutation.isLoading.value
            "
            class="flex items-center w-full py-2 pl-4 pr-2 rounded-[4px] mb-[2px] transition duration-150 hover:bg-gray-100 text-sm font-medium leading-none disabled:opacity-60"
            type="button"
            @click="onSelect(company)"
          >
            <img
              :alt="`${company.name} logo`"
              :src="company.logoSrc"
              class="rounded-full mr-2 flex-shrink-0"
              height="18"
              width="18"
            />

            <span class="truncate mr-auto">
              {{ company.name }}
            </span>

            <t-icon
              is="check"
              v-if="company.id === authenticatedCompany.id"
              class="text-info mr-2 flex-shrink-0"
              solid
            />
          </button>
        </div>
      </div>
    </div>
    <logout-user />
  </t-popover>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
