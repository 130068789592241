<template>
  <div
    class="min-h-screen login-container backdrop-blur-3xl w-full py-16 px-4 bg-white flex"
  >
    <div class="flex-1 max-w-[500px] mx-auto">
      <main-logo-big class="mb-10 mx-auto" />
      <slot />
    </div>
  </div>
</template>

<script>
import MainLogoBig from "@/components/atoms/main-logo-big.vue";

export default {
  name: "authLayout",
  components: { MainLogoBig },
};
</script>
<style>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
}
</style>
