<template>
  <span class="t-error-message block text-error" role="alert">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "t-error-message",
});
</script>

<style>
.t-error-message {
  @apply text-xs;
}
</style>
