<template>
  <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M47.9573 23.9787C47.9573 10.7356 37.2217 8.69879e-06 23.9787 9.27767e-06C10.7356 9.85654e-06 -1.62701e-06 10.7356 -1.04814e-06 23.9787C-4.69268e-07 37.2217 10.7356 47.9573 23.9787 47.9573C37.2217 47.9573 47.9573 37.2217 47.9573 23.9787Z"
        fill="#BAC4E0"/>
    <path
        d="M24.0155 33.9014C29.1656 33.9014 33.3406 28.1194 33.3406 20.9869C33.3406 13.8545 29.1656 8.07245 24.0155 8.07245C18.8654 8.07245 14.6904 13.8545 14.6904 20.9869C14.6904 28.1194 18.8654 33.9014 24.0155 33.9014Z"
        fill="white"/>
    <path
        d="M23.8677 33.0503C16.0598 33.0503 9.16254 35.0767 4.5 38C8.8295 44.1427 15.9117 48 24.0157 48C32.0456 48 39.1705 44.0317 43.5 38C38.8745 34.9657 31.7866 33.0503 23.8677 33.0503Z"
        fill="white"/>
    <path
        d="M25.4222 39.267H22.5729C20.6117 39.267 19.0205 37.6759 19.0205 35.7146V27.6107C19.0205 25.6495 20.6117 24.0583 22.5729 24.0583H25.4222C27.3835 24.0583 28.9747 25.6495 28.9747 27.6107V35.7146C28.9747 37.6759 27.3835 39.267 25.4222 39.267Z"
        fill="white"/>
    <path
        d="M16.1707 21.727C16.4667 23.7992 15.9857 25.5754 15.1346 25.7234C14.2835 25.8715 13.3584 24.3173 13.0993 22.2451C12.8033 20.1728 13.2844 18.3966 14.1355 18.2486C14.9496 18.0636 15.8747 19.6548 16.1707 21.727Z"
        fill="white"/>
    <path
        d="M31.8611 21.727C31.565 23.7992 32.0461 25.5754 32.8972 25.7234C33.7483 25.8715 34.6734 24.3173 34.9324 22.2451C35.2284 20.1728 34.7474 18.3966 33.8963 18.2486C33.0452 18.0636 32.1201 19.6548 31.8611 21.727Z"
        fill="white"/>
    <path
        d="M15.0243 20.4689C14.7282 18.3966 14.3952 16.3244 14.0991 14.2522C13.9881 13.5121 13.8771 12.698 14.1362 11.9949C14.3952 11.2918 15.2093 10.7367 15.9124 10.9958C15.6163 9.55261 17.2075 8.47949 18.6137 8.03544C20.9449 7.22134 23.4242 6.8143 25.8665 7.14734C28.3088 7.48038 30.677 8.5535 32.3422 10.3667C32.8603 10.9588 33.3044 11.5878 33.5634 12.3279C34.0444 13.7341 33.7484 15.2883 33.4154 16.7684C33.1563 17.9156 32.9343 19.0997 32.6753 20.2468C32.6013 20.5059 32.5643 20.8019 32.3792 20.9869C32.0832 21.32 31.6022 21.32 31.1581 21.32C26.5326 21.32 21.907 21.357 17.2815 21.357C16.4304 21.357 15.2833 21.1349 15.1723 20.2838"
        fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "default-user-avatar"
}
</script>

<style scoped>

</style>