<template>
  <div v-if="isVisible" class="inline-block">
    <div
      class="text-[#fff] font-semibold align-bottom inline-block ml-3 align-top h-6"
    >
      <router-link
        to="/settings?tab=billing"
        :class="{
          'bg-info': billing.subscription?.status == 'active',
          'bg-primary': billing.subscription?.status == 'canceled',
        }"
        class="p-2 px-3 rounded-full text-sm"
      >
        {{ billingStore.plans[billing.subscription?.plan] }}
        plan<span
          class="text-xs"
          v-if="billing.subscription?.status == 'trial' || true"
          >. Trial ends on
          {{
            dayjs(billing?.subscription?.paidUntil)
              .add(1, "day")
              .format("d MMM")
          }} </span
        ><span class="text-xs" v-if="billing.subscription?.status == 'canceled'"
          >. Cancelled
        </span>
        <t-icon
          v-if="billing.subscription?.status == 'active'"
          is="rocket-launch"
          class="ml-2"
        />
        <t-icon
          v-if="billing.subscription?.status == 'canceled'"
          is="triangle-exclamation"
          solid
          class="ml-2"
        />
      </router-link>
    </div>

    <div
      v-if="billing"
      class="text-[#505050] font-semibold align-bottom ml-1 mt-2 align-bottom text-xs hidden"
    >
      {{ billing.projects?.total }} of {{ billing.current?.max }} projects
    </div>
    <!--    <div class="h-5 hidden">-->
    <!--      <div-->
    <!--        class="gauge-bg h-[8px] rounded-full w-[200px] bg-[#E6E6E6] align-bottom mt-2 self-baseline"-->
    <!--      >-->
    <!--        <div-->
    <!--          v-if="billing.settings"-->
    <!--          :style="`width:${-->
    <!--            (billing.settings.projects.total / projects?.billing.current.max) *-->
    <!--            100-->
    <!--          }%`"-->
    <!--          :class="[-->
    <!--            projects.pageInfo.totalResults == projects?.billing.current.max-->
    <!--              ? 'bg-[#DB1B4A]'-->
    <!--              : 'bg-[#0d62fe]',-->
    <!--          ]"-->
    <!--          class="rounded-full gauge-fill h-[8px]"-->
    <!--        ></div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script setup lang="ts">
import { TIcon } from "@/components/atoms";
import { useBillingStore } from "@/stores/billing-store";
import { useUIStore } from "@/stores/ui-store";
import { computed, onMounted, ref } from "vue";
import { useDayjs } from "@/plugins/dayjs.plugin";

const billingStore = useBillingStore();
const uiStore = useUIStore();
const dayjs = useDayjs();
onMounted(async () => {
  await billingStore.load();
});

const billing = computed(() => billingStore.settings);

const isVisible = ref(true); //ref(uiStore.isCompanySpecial());
</script>
