import { inject } from "vue";

export const MODAL_CONTEXT = Symbol('Modal')

export type ModalContext = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

export function useModal() {
    const Context = inject<ModalContext>(MODAL_CONTEXT)

    if (!Context) {
        throw new Error('useModal called outside of t-modal')
    }

    return Context
}