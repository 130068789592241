<template>
  <ul v-for="(link, index) in menuConfig" :key="index" class="mx-4">
    <router-link
      :data-qa="link.dataQa"
      :to="link.url !== '' ? link.url : ''"
      @click.stop="onLinkClick()"
    >
      <li
        :class="{
          active:
            (index === activeItem || this.$route.path.includes(link.url)) &&
            link.url != '',
          'cursor-default': link.url == '',
        }"
        class="cursor-pointer text-sm font-semibold leading-6 hover:bg-blue-50 hover:text-black text-gray-800 focus:outline-none pl-8 py-[7px] my-1 flex items-center w-full"
      >
        <span class="platform-icon"
          ><component :is="linkLogoIconMap[link.logo]" class="platform-icon" />
        </span>

        <span class="px-4">{{ link.title }} </span>
      </li>
    </router-link>
  </ul>
</template>

<script>
import Facebook from "@/components/icons/facebook.vue";
import AllProjects from "@/components/icons/all-projects.vue";
import GoogleSearchConsole from "@/components/icons/google-search-console.vue";
import GoogleAds from "@/components/icons/google-ads.vue";
import { mapWritableState } from "pinia/dist/pinia.esm-browser";
import { useUIStore } from "@/stores/ui-store";

export default {
  name: "inside-menu",
  components: { GoogleAds, GoogleSearchConsole, AllProjects, Facebook },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapWritableState(useUIStore, ["isSubnavOpen", "isOpenMonitoring"]),
  },
  props: {
    menuConfig: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      linkLogoIconMap: {
        "all-projects": "all-projects",
        facebook: "facebook",
        "google-search-console": "google-search-console",
        "google-ads": "google-ads",
        "google-analytics": "google-analytics",
      },
      activeItem: null,
      currentRoute: "",
      isLinkClicked: false,
    };
  },
  methods: {
    onLinkClick(index, e) {
      this.activeItem = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  @apply text-black bg-blue-50;
}

.platform-icon {
  width: auto !important;
  height: 18px !important;
}
</style>
