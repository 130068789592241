import { defineStore } from "pinia";

export const useNavStore = defineStore({
  id: "nav-store",
  state: () => {
    return {
      analysisConfig: [
        {
          url: "/all-channels-analysis",
          title: "All channels",
          logo: "all-projects",
          color: "google-analytics",
          platform: "",
        },
        {
          url: "/performance-analysis/facebook-ads",
          title: "Facebook Ads",
          logo: "facebook",
          color: "facebook-ads",
          platform: "FACEBOOK_ADS",
        },
        {
          url: "/performance-analysis/google-search-console",
          title: "Google Search Console",
          logo: "google-search-console",
          color: "google-search-console",
          platform: "GOOGLE_SEARCH_CONSOLE",
        },
        {
          url: "/performance-analysis/google-ads",
          title: "Google Ads",
          logo: "google-ads",
          color: "google-ads",
          platform: "GOOGLE_ADS",
        },
      ],
      monitorConfig: [
        {
          url: "/monitoring/all-projects",
          title: "All channels",
          logo: "all-projects",
          color: "google-analytics",
        },
        {
          url: "/monitoring/2",
          title: "Facebook Ads",
          logo: "facebook",
          color: "facebook-ads",
        },
        {
          url: "/monitoring/3",
          title: "Google Search Console",
          logo: "google-search-console",
          color: "google-search-console",
        },
        {
          url: "/monitoring/5",
          title: "Google Ads",
          logo: "google-ads",
          color: "google-ads",
        },
      ],
      linkLogoIconMap: {
        "all-projects": "all-projects",
        facebook: "facebook",
        "google-search-console": "google-search-console",
        "google-ads": "google-ads",
      },
    };
  },
});
