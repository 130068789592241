<template>
  <div class="group mx-4">
    <router-link v-if="routeName"
        v-slot="{ href, navigate }"
        :custom="true"
        :to="routeName != '' ? { name: routeName } : ''"
    >
      <a
          :class="{ 'accent-link bg-blue-100': isSelected, 'cursor-default': routeName == '' }"
          :href="href"
          class="flex w-full px-3 py-2 my-1 font-bold group-hover:bg-blue-100 transition-all duration-500 ease-in-out items-center justify-between "
          @click="onLinkClick($event, navigate)"
      >
        <span class="flex items-center justify-between">
          <t-icon
              :is="icon"
              :class="{ 'accent-link': isSelected, 'text-gray-800': !isSelected }"
              class="group-hover:text-black text-lg fa-fw"
          >
          </t-icon>

          <span
              :class="[
              isSlimMenuShown ? 'hidden' : '',
              { 'accent-link': isSelected },
            ]"
              class="text-base font-medium font-sans group-hover:text-black px-4">
          {{ title }}
              </span>
          </span>
        <t-icon :is="arrowIcon"
                v-if="arrowIcon && isSlimMenuShown == false"
                :class="isSlimMenuShown ? 'hidden' : '',
                {
                'rotate-90': isSelected,
                '': !isSelected,
              }"
                class="transition-all"
        ></t-icon>
      </a>
    </router-link>
    
   
      <span v-else
          :class="{ 'accent-link bg-blue-100': isSelected, 'cursor-default': routeName == '' }"
          class="flex w-full px-3 py-2 my-1 font-bold group-hover:bg-blue-100 transition-all duration-500 ease-in-out items-center justify-between "
      >
        <span class="flex items-center justify-between">
          <t-icon
              :is="icon"
              :class="{ 'accent-link': isSelected, 'text-gray-800': !isSelected }"
              class="group-hover:text-black text-lg fa-fw"
          >
          </t-icon>

          <span
              :class="[
              isSlimMenuShown ? 'hidden' : '',
              { 'accent-link': isSelected },
            ]"
              class="text-base font-medium font-sans group-hover:text-black px-4">
          {{ title }}
              </span>
          </span>
        <t-icon :is="arrowIcon"
                v-if="arrowIcon && isSlimMenuShown == false"
                :class="isSlimMenuShown ? 'hidden' : '',
                {
                'rotate-90': isSelected,
                '': !isSelected,
              }"
                class="transition-all"
        ></t-icon>
            </span>
  </div>
</template>
<script>
import TIcon from "@/components/atoms/t-icon.vue";
import {mapWritableState} from "pinia";
import {useUIStore} from "@/stores/ui-store";

export default {
  components: {TIcon},
  computed: {
    ...mapWritableState(useUIStore, ["isSubnavOpen", "isOpenMonitoring"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    isSlimMenuShown: {
      type: Boolean,
      default: false,
    },
    routeName: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    arrowIcon: {
      type: String,
      required: false,
    },
    cursorPointer: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onLinkClick(event, navigate) {
      this.$emit("clicked", this.routeName);
      navigate(event);
      this.isActive = true;
    },
  },
};
</script>
<style>

.accent-link {
  color: black;
}

</style>
