import type { App } from "vue";
import Toast, { type PluginOptions, useToast, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

export const defineToastPlugin = (app: App) => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_LEFT,
    draggable: false,
    closeOnClick: false,
  };

  app.use(Toast, options);
};

export { useToast };
