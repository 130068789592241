<script lang="ts" setup>
import { GlobalEvents } from "vue-global-events";
import { createPopper, type Instance, type Placement } from "@popperjs/core";
import { useVModel } from "@vueuse/core";
import {
  Teleport,
  Transition,
  ref,
  computed,
  type PropType,
  onUnmounted,
  watch,
} from "vue";
import { SlotProxy } from "./slot-proxy";

/**
 * <t-popover>
 *   <template v-slot:reference>
 *     Button goes here
 *    </template>
 *
 *    Content goes here
 * </t-popover>
 */

const emit = defineEmits([
  "update:isOpen",
  "open", // Beginning to transition
  "close", // Beginning to transition
  "opened", // Transition has completed
  "closed", // Transition has completed
]);

const props = defineProps({
  /**
   * Use v-model:isOpen="reactiveVariableGoesHere" to manually control
   * the open state of the popover
   */
  isOpen: {
    type: Boolean,
    default: undefined,
  },

  /**
   * Placement for the popover relative to the reference element.
   * `bottom` means centered below the reference
   * `bottom-start` means below the reference, beginning from its left side
   * `bottom-emd` means below the reference, beginning from its right side
   */
  placement: {
    type: String as PropType<Placement>,
    default: "bottom-start",
  },

  /**
   * Appends the popover to the end of </body>
   */
  teleport: {
    type: Boolean,
    default: true,
  },

  /**
   * When this is true, the contents of the popover will not render in the browser
   * unless the popover is opened
   */
  unmount: {
    type: Boolean,
    default: true,
  },

  /**
   * The strategy used for positioning the popover.
   * Defaults to `absolute`, use `fixed` if the reference is
   * inside of a fixed/sticky element
   */
  strategy: {
    type: String as PropType<"fixed" | "absolute">,
    default: "absolute",
  },

  sticky: {
    type: Boolean,
    default: false,
  },

  /**
   * The element which will receive initial focus after the popover
   * has completed its enter transition.
   */
  initialFocusRef: {
    type: Object as PropType<{ focus: () => void }>,
  },

  // How far the popover is from the reference element
  offset: {
    type: Number,
    default: 12,
  },

  /**
   * Whether or not to display an arrow along with the popover which points to the reference
   * @TODO
   * This is currently used only in Query-Filter and LandingPageFilter components and defaults to false
   * because it is not well standardized.
   * The offset on these components is larger than the remaining ones ( which causes issues with the size of the arrow )
   * used in other pages in the design and the popover there is borderless which cuases conflicts with the arrow
   * ( it must have the same style as the popover itself )
   */
  arrow: {
    type: Boolean,
    default: false,
  },

  /**
   * Classes added to the root element
   */
  rootClass: {},

  reference: {
    type: Object as PropType<HTMLElement>,
  },
});

function findFocusableElement(root: HTMLElement) {
  const focusableElements = root.querySelectorAll(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
  );

  return (
    Array.from(focusableElements).find((element) => {
      return element.getAttribute("data-focus-trap") !== "";
    }) ?? null
  );
}

const referenceRef = ref<HTMLElement | { $el: HTMLElement }>();
const popover = ref<HTMLElement>();
const popper = ref<Instance>();
const arrowRef = ref<HTMLElement>();

const referenceElement = computed(() => {
  if (props.reference) {
    return props.reference;
  }

  if (!referenceRef.value) {
    return null;
  }

  if (referenceRef.value instanceof HTMLElement) {
    return referenceRef.value;
  }

  return referenceRef.value.$el;
});

function activatePopper() {
  if (popper.value) {
    return popper.value.update();
  }

  if (!referenceElement.value || !popover.value) {
    return;
  }

  const modifiers = [];

  modifiers.push({
    name: "offset",
    options: {
      offset: [0, props.offset],
    },
  });

  // Events are disabled for performance reasons, see if this causes any issues
  // The difference is that while a popup is open, scrolling or resizing the window will not
  // cause it to recompute its position
  // modifiers.push({
  //   name: "eventListeners",
  //   options: {
  //     scroll: false,
  //     resize: false
  //   }
  // })

  if (props.arrow) {
    modifiers.push({
      name: "arrow",
      options: {
        element: arrowRef.value as HTMLElement,
      },
    });
  }

  popper.value = createPopper(referenceElement.value, popover.value, {
    placement: props.placement,
    strategy: props.strategy,
    modifiers,
  });
}

function deactivatePopper() {
  if (popper.value) {
    popper.value.destroy();
    popper.value = undefined;
  }
}

onUnmounted(() => deactivatePopper());

function handleAfterEnter() {
  isTransitioning.value = false;

  emit("opened");

  props.initialFocusRef?.focus?.();
}

function handleAfterLeave() {
  deactivatePopper();
  isTransitioning.value = false;
  restoreFocus();
  emit("closed");
}

function handleReferenceEscKeydown(event: KeyboardEvent) {
  /**
   * If the popover is open, we want to close it.
   * We use preventDefault so that if Popover is used inside a modal, the escape
   * keypress only closes the popover.
   */
  if (internalIsOpen.value) {
    close();
    event.preventDefault();
  }
}

function restoreFocus() {
  referenceElement.value?.focus();
}

function handleDocumentClick(event: MouseEvent) {
  // If they clicked on the button, it manages its own state
  if (referenceElement.value?.contains(event.target)) {
    return;
  }

  // If clicked inside of the popover, we don't have to do anything
  if (popover.value?.contains(event.target)) {
    return;
  }

  if (props.sticky == true) {
    return;
  }

  if (event.target?.tabIndex === 0) {
    // @TODO skip focus restoration
  }

  close();
}

const referenceAttributes = {
  ref: referenceRef,
  onClick: () => {
    if (internalIsOpen.value) {
      return close();
    }

    open();
  },
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      if (!internalIsOpen.value || !popover.value) {
        return;
      }

      /**
       * When Tab is pressed while focusing the button and the popover is open
       * prevent the default action and move the focus to the popover component
       */
      event.preventDefault();

      // If its open, shift the focus to the first focusable element
      // inside the popover
      findFocusableElement(popover.value)?.focus();
    }
  },
};

async function open(): Promise<void> {
  internalIsOpen.value = true;

  return new Promise((resolve) => setTimeout(resolve, 100));
}

async function close(): Promise<void> {
  internalIsOpen.value = false;
  emit("update:isOpen", false);
  emit("close");
  emit("closed");

  return new Promise((resolve) => setTimeout(resolve, 100));
}

/**
 * If the user added v-model:isOpen or :isOpen, we will manage the visible state by emitting events
 * instead of directly closing the popover.
 * If its not used, we will manage the state internally
 */
const isControlled = computed(() => {
  return props.isOpen !== undefined;
});

const publicModelValue = useVModel(props, "isOpen");
const internalModelValue = ref(props.isOpen);

const internalIsOpen = computed({
  get: () => {
    return isControlled.value
      ? publicModelValue.value
      : internalModelValue.value;
  },
  set: (value) => {
    if (isControlled.value) {
      return (publicModelValue.value = value);
    }

    internalModelValue.value = value;
  },
});

/**
 * This is a hack necessary to facilitate the transitions of the component
 * The variable is set to true when `isOpen` changes, which tells the parent component
 * to render itself. This is necessary specifically when the popover is being hidden,
 * it prevents the parent component from unmounting until the transition is finished
 */
const isTransitioning = ref(false);

watch(internalIsOpen, () => (isTransitioning.value = true));

defineExpose({
  close,
  open,
  isOpen: internalIsOpen,
});
</script>

<template>
  <SlotProxy
    v-bind="referenceAttributes"
    @keydown.esc="handleReferenceEscKeydown"
  >
    <slot
      name="reference"
      :open="open"
      :close="close"
      :isOpen="internalIsOpen"
    />
  </SlotProxy>

  <GlobalEvents v-if="internalIsOpen" @mousedown="handleDocumentClick" />

  <Teleport
    v-if="isTransitioning ? true : unmount ? internalIsOpen : true"
    to="body"
    :disabled="teleport === false"
  >
    <div
      ref="popover"
      class="t-popover-root z-[101]"
      tabindex="0"
      :class="rootClass"
    >
      <Transition
        appear
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
        @enter="
          activatePopper();
          emit('open');
        "
        @leave="emit('close')"
        @after-enter="handleAfterEnter"
        @after-leave="handleAfterLeave"
      >
        <div class="t-popover" v-show="internalIsOpen">
          <span
            v-if="arrow"
            ref="arrowRef"
            class="t-popover-arrow text-[35px] text-white pointer-events-none"
          ></span>

          <div
            class="bg-white rounded-lg shadow-lg p-3 border border-gray-200"
            v-bind="$attrs"
          >
            <span
              class="sr-only"
              tabindex="0"
              data-focus-trap
              @focus="restoreFocus"
            ></span>
            <slot :open="open" :close="close" :isOpen="internalIsOpen" />
            <span
              class="sr-only"
              tabindex="0"
              data-focus-trap
              @focus="close"
            ></span>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style>
.t-popover-arrow,
.t-popover-arrow::before {
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 4px;
  z-index: -1;
}

.t-popover-arrow::before {
  content: "";
  transform: rotate(45deg);
  background-color: currentColor;
}

.t-popover-root[data-popper-placement^="top"] > .t-popover > .t-popover-arrow {
  bottom: -0.25em;
}

.t-popover-root[data-popper-placement^="right"]
  > .t-popover
  > .t-popover-arrow {
  left: -0.25em;
}

.t-popover-root[data-popper-placement^="bottom"]
  > .t-popover
  > .t-popover-arrow {
  top: -0.25em;
}

.t-popover-root[data-popper-placement^="left"] > .t-popover > .t-popover-arrow {
  right: -0.25em;
}
</style>
