import * as Sentry from "@sentry/vue";
import axios, { Axios } from "axios";
import axiosRetry from "axios-retry";
import { inject, type App } from "vue";

/**
 * @description
 * Exports an axios instance with the backend url preconfigured.
 * The instance is also configured to never throw errors.
 * When performing queries, make sure to verify the `response.status` property
 * to make sure that you are handling errors appropriately
 */

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  withCredentials: true,
});

axiosRetry(axiosInstance, {
  retries: import.meta.env.DEV ? 0 : 3,
  retryCondition(error) {
    if (error.request && !error.response) {
      return true;
    }

    if (error.response) {
      return error.response.status >= 500 || error.response.status === 429;
    }

    return false;
  },
  retryDelay: (retries) => {
    return retries * 1000;
  },
});

axiosInstance.interceptors.response.use((response) => {
  /**
   * Add a generic error that will be displayed to the user.
   * If this error occurred, it means that the input sent did not pass the
   * initial validation and no business logic was executed. The client-side validation
   * needs to be adjusted so that it doesnt allow invalid input to be submitted at all.
   */
  if (response.data.type === "BAD_INPUT") {
    Sentry.captureException(
      new Error(`Bad input sent to ${response.config.url}`),
      {
        extra: {
          parameters: JSON.stringify(response.data),
        },
      },
    );

    response.data.error = {
      message: "Server error. Please try again later.",
      code: "BAD_INPUT",
    };
  }

  // @TODO Report error to sentry when it is properly set up

  return response;
});

export function useDefaultAxios() {
  const axios = inject<Axios>("$axios");

  if (!axios) {
    return axiosInstance;
    // throw new Error("useDefaultAxios called outside of a Vue component");
  }

  return axios;
}

export function defineDefaultAxiosPlugin(app: App) {
  app.config.globalProperties.$axios = axiosInstance;
  app.config.globalProperties.$pinia.use(() => ({ $axios: axiosInstance }));
  app.provide("$axios", axiosInstance);

  return axiosInstance;
}
