<template>
  <div
    :class="{ 'bg-blue-400 bg-opacity-10': status == 'created' }"
    class="flex items-center justify-between border-b border-gray-200 py-2 pl-3 mb-1 rounded-md"
  >
    <div class="flex items-center">
      <t-icon is="circle-info" solid />
      <div class="ml-4">
        <h3 class="text-lg font-medium">
          {{ title }}
        </h3>
        <p class="text-sm font-normal text-gray-400">
          {{ notification }}
        </p>
      </div>
    </div>
    <span class="text-gray-400 hover:text-primary cursor-pointer px-2">
      <!--      <t-button color="dark" square size="lg" link>-->
      <!--        <t-icon is="xmark" />-->
      <!--      </t-button>-->
    </span>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useUIStore } from "@/stores/ui-store";
import { mapWritableState } from "pinia";
import { TIcon, TButton } from "@/components/atoms";
import { useUserStore } from "@/stores/user-store";

export default defineComponent({
  name: "NotificationMenuItem",
  components: { TIcon, TButton },
  props: {
    platforms: {
      type: Array,
    },
    title: {
      type: String,
      default: "Team Trackian",
    },
    notification: {
      type: String,
      default: "Metric data for project is extracted",
    },
    status: {
      type: String,
      default: "created",
    },
  },
  data: () => ({
    openGroup: false,
  }),
  methods: {},
  computed: {
    ...mapWritableState(useUIStore, ["isSubnavOpen"]),
    ...mapWritableState(useUserStore, ["user"]),
  },
});
</script>
<style scoped></style>
