<template>
  <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 4.04999C0 1.81324 1.81324 0 4.04999 0C6.28673 0 8.09998 1.81324 8.09998 4.04999C8.09998 6.28673 6.28673 8.09998 4.04999 8.09998C1.81324 8.09998 0 6.28673 0 4.04999Z"
        fill="#FABC04"/>
    <path
        d="M9.8999 4.04999C9.8999 1.81324 11.7131 0 13.9499 0C16.1866 0 17.9999 1.81324 17.9999 4.04999C17.9999 6.28673 16.1866 8.09998 13.9499 8.09998C11.7131 8.09998 9.8999 6.28673 9.8999 4.04999Z"
        fill="#34A852"/>
    <path
        d="M0 13.9499C0 11.7131 1.81324 9.8999 4.04999 9.8999C6.28673 9.8999 8.09998 11.7131 8.09998 13.9499C8.09998 16.1866 6.28673 17.9999 4.04999 17.9999C1.81324 17.9999 0 16.1866 0 13.9499Z"
        fill="#3C8BD9"/>
    <path
        d="M9.8999 13.9499C9.8999 11.7131 11.7131 9.8999 13.9499 9.8999C16.1866 9.8999 17.9999 11.7131 17.9999 13.9499C17.9999 16.1866 16.1866 17.9999 13.9499 17.9999C11.7131 17.9999 9.8999 16.1866 9.8999 13.9499Z"
        fill="#D0D1D2"/>
  </svg>
</template>

<script lang="ts">
export default {
  name: "all-projects",
};
</script>

<style scoped></style>
