import { useUserStore } from "@/stores/user-store";

export function pushDataLayer(data) {
  if (process.env.NODE_ENV == "development") return;
  const userStore = useUserStore();
  const intercomHash = userStore?.intercomHash;
  window?.dataLayer?.push({
    ...data,
    hash: intercomHash || null,
  });
}
