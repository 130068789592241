import { useUserStore } from "@/stores/user-store";
import { useMutation } from "vue-query";
export function useAuthenticateAsMemberMutation() {
  const userStore = useUserStore();
  return useMutation({
    mutationFn: async (companyId: number) => {
      await userStore.authenticateAsMember(companyId);
    },
  });
}
