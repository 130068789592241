<template>
  <div class=" rounded-r-lg">
    <h2
        class="pl-8 flex w-full py-3 mb-3 font-medium font-sans text-base bg-blue-100 cursor-pointer transition-all duration-500 ease-in-out items-center cursor-pointer"
    >
      {{ menuTitle }}
    </h2>
    <ul v-for="(link, index) in menuConfig"
        :key="index"
        class="bg-gray-50">
      <router-link
          :data-qa="link.dataQa"
          :to="link.url !== '' ?  link.url  : ''"
          @click="onLinkClick(index)"
      >
        <li
            :class="{ active: index === activeItem && link.url != '' || link.url === this.$route.fullPath && link.url != '', 'cursor-default': link.url == ''}"
            class="py-2 cursor-pointer hover:bg-blue-50 font-semibold text-sm text-gray-800 focus:outline-none pl-8 my-1 hover:text-black flex w-full leading-6"
        >
       <span class="platform-icon"><component :is="linkLogoIconMap[link.logo]" class="platform-icon"/>
        </span>
          <span class="px-3"
          >{{ link.title }}

          </span
          >
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>

import Facebook from "@/components/icons/facebook.vue";
import AllProjects from "@/components/icons/all-projects.vue";
import GoogleSearchConsole from "@/components/icons/google-search-console.vue";
import GoogleAds from "@/components/icons/google-ads.vue";

export default {
  name: "side-menu",
  components: {AllProjects, Facebook, GoogleSearchConsole, GoogleAds},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      linkLogoIconMap: {
        'all-projects': 'all-projects',
        'facebook': 'facebook',
        'google-search-console': 'google-search-console',
        'google-ads': 'google-ads',
        'google-analytics': 'google-analytics'
      },
      activeItem: null
    }
  },
  props: {
    menuConfig: {
      type: Array,
      default: [],
    },
    menuTitle: {
      default: ""
    }
  },
  methods: {
    onLinkClick(index) {
      this.activeItem = index
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  @apply text-black
}

.platform-icon {
  width: 18px !important;
  height: 18px !important;
}
</style>