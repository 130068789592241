import { useToast } from "vue-toastification";
import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "../stores/user-store";
import { pushDataLayer } from "@/services/dataLayer.service";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      // There's nothing on this route currently, so we check if there's a logged in user
      // and redirect him to either the projects list or the login page
      path: "/",
      name: "home",
      redirect() {
        return {
          name: "projects",
        };
      },
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/auth/login",
      name: "login",
      component: () => import("../views/auth/LoginView.vue"),
      beforeEnter() {
        const { isGuest, isAuthenticatedAsMember } = useUserStore();

        if (isGuest) {
          return true;
        }

        if (isAuthenticatedAsMember === false) {
          return { name: "auth_company" };
        }

        return {
          name: "home",
        };
      },
      meta: {
        public: true,
        layout: "auth",
      },
    },
    {
      path: "/auth/share-gate",
      name: "share-code",
      component: () => import("../views/auth/ShareCodeView.vue"),
      beforeEnter() {
        const { isGuest, isAuthenticatedAsMember } = useUserStore();

        if (isGuest) {
          return true;
        }

        if (isAuthenticatedAsMember === false) {
          return { name: "auth_company" };
        }

        return {
          name: "home",
        };
      },
      meta: {
        public: true,
        layout: "auth",
      },
    },
    {
      path: "/auth/company",
      name: "auth_company",
      component: () => import("../views/auth/company.view.vue"),
      meta: {
        layout: "auth",
      },
      beforeEnter(to) {
        const { isGuest, isAuthenticatedAsMember } = useUserStore();

        if (isGuest) {
          return { name: "login" };
        }

        if (isAuthenticatedAsMember) {
          return {
            name: "home",
          };
        }

        return true;
      },
    },
    {
      path: "/auth/register",
      name: "register",
      component: () => import("../views/auth/RegisterView.vue"),
      meta: {
        public: true,
        layout: "auth",
      },
    },
    {
      path: "/projects/edit",
      name: "AddOrEditProjects",
      component: () => import("../views/AddOrEditProjects.vue"),
    },
    {
      path: "/projects/create",
      name: "projects_create",
      component: () =>
        import("@/modules/projects/views/projects-create.view.vue"),
    },
    {
      path: "/projects/:id",
      name: "projects_edit",
      component: () =>
        import("@/modules/projects/views/projects-edit.view.vue"),
    },
    {
      path: "/monitoring/:platform",
      name: "monitoring_platform",
      component: () => import("@/modules/monitoringV2/Monitoring.vue"),
    },
    {
      path: "/monitoring/all-projects",
      name: "monitoring_all_projects",
      component: () => import("@/modules/monitoringV2/Monitoring.vue"),
    },
    {
      path: "/monitoring/:sourceId/edit/:projectId",
      name: "monitoringv2_all_projects_edit",
      component: () => import("@/modules/monitoringV2/ProjectEdit.vue"),
    },
    {
      path: "/performance-analysis/facebook-ads",
      name: "performance_analysis_facebook_ads",
      component: () =>
        import(
          "@/modules/performance-analysis/facebook-ads/views/performance-analysis.view.vue"
        ),
    },
    {
      path: "/performance-analysis/facebook-ads/:project",
      name: "performance_analysis_facebook_ads_project",
      component: () =>
        import(
          "@/modules/performance-analysis/facebook-ads/views/performance-analysis-project.view.vue"
        ),
    },
    {
      path: "/performance-analysis/google-search-console",
      name: "performance_analysis_google_search_console",
      component: () =>
        import(
          "@/modules/performance-analysis/google-search-console/views/performance-analysis.view.vue"
        ),
    },
    {
      path: "/performance-analysis/google-search-console/:project",
      name: "performance_analysis_google_search_console_project",
      component: () =>
        import(
          "@/modules/performance-analysis/google-search-console/views/performance-analysis-project.view.vue"
        ),
    },
    {
      path: "/performance-analysis/google-ads",
      name: "performance_analysis_google_ads",
      component: () =>
        import(
          "@/modules/performance-analysis/google-ads/views/performance-analysis.view.vue"
        ),
    },
    {
      path: "/performance-analysis/google-ads/:project",
      component: () =>
        import(
          "@/modules/performance-analysis/google-ads/views/performance-analysis-project.view.vue"
        ),
      children: [
        {
          path: "",
          name: "performance_analysis_google_ads_project",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-all-campaigns.view.vue"
            ),
        },
        {
          path: "search",
          name: "performance_analysis_google_ads_project_search",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-search.view.vue"
            ),
        },
        {
          path: "display",
          name: "performance_analysis_google_ads_project_display",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-display.view.vue"
            ),
        },
        {
          path: "performance_max",
          name: "performance_analysis_google_ads_project_performance_max",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-performance-max.view.vue"
            ),
        },
        {
          path: "shopping",
          name: "performance_analysis_google_ads_project_shopping",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-shopping.view.vue"
            ),
        },
        {
          path: "video",
          name: "performance_analysis_google_ads_project_video",
          component: () =>
            import(
              "@/modules/performance-analysis/google-ads/views/performance-analysis-video.view.vue"
            ),
        },
      ],
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("@/modules/projects/views/projects.view.vue"),
    },
    {
      path: "/projects/targets",
      name: "Project Targets",
      component: () => import("../views/ProjectTargets.vue"),
    },
    {
      path: "/ComponentsPlayground",
      name: "Components Playground",
      component: () => import("../views/ComponentsPlayground.vue"),
      meta: {
        layout: "defaultLayout",
      },
    },
    {
      path: "/onboarding/create-company",
      name: "create-company",
      component: () => import("../views/onboarding/create-company.vue"),
      meta: {
        layout: "onboarding",
      },
    },
    {
      path: "/onboarding/connect-platforms",
      name: "/onboarding/connect-platforms",
      component: () => import("../views/onboarding/connect-platforms.vue"),
      meta: {
        layout: "onboarding",
      },
    },
    {
      path: "/onboarding/pricing",
      name: "/onboarding/pricing",
      component: () => import("../views/onboarding/pricing.vue"),
      meta: {
        public: true,
        layout: "onboarding",
      },
    },
    {
      path: "/onboarding/create-project",
      name: "/onboarding/create-project",
      component: () => import("../views/onboarding/create-project.vue"),
      meta: {
        layout: "onboarding",
        public: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/modules/settings/views/settings.view.vue"),
      meta: {
        layout: "default",
      },
      children: [
        {
          path: "",
          name: "settings_user_profile",
          component: () =>
            import("@/modules/settings/views/settings-user-profile.view.vue"),
        },
        {
          path: "change-password",
          name: "settings_change_password",
          component: () =>
            import(
              "@/modules/settings/views/settings-change-password.view.vue"
            ),
        },
        {
          path: "connect-platforms",
          name: "settings_connect_platforms",
          component: () =>
            import(
              "@/modules/settings/views/settings-connect-platforms.view.vue"
            ),
        },
        {
          path: "company-profile",
          name: "settings_company_profile",
          component: () =>
            import(
              "@/modules/settings/views/settings-company-profile.view.vue"
            ),
        },
        {
          path: "company-members",
          name: "settings_company_members",
          component: () =>
            import(
              "@/modules/settings/views/settings-company-members.view.vue"
            ),
        },
        {
          path: "invitations",
          name: "settings_invitations",
          component: () =>
            import("@/modules/settings/views/settings-invitations.view.vue"),
        },
        {
          path: "notifications",
          name: "settings_notifications",
          component: () =>
            import("@/modules/settings/views/settings-notifications.view.vue"),
        },
        {
          path: "anomaly",
          name: "anomaly_detection",
          component: () =>
            import("@/modules/settings/views/anomaly-detection.view.vue"),
        },
        {
          path: "billing",
          name: "billing_notifications",
          component: () => import("@/modules/settings/views/billing.view.vue"),
        },
        {
          path: "payment",
          name: "settings_payment",
          component: () => import("@/modules/settings/views/settings-payment-method.view.vue"),
        },
        {
          path: "history",
          name: "settings_history",
          component: () =>
              import("@/modules/settings/views/settings-billing-history.view.vue"),
        },
        {
          path: "plan",
          name: "settings_plan",
          component: () =>
              import("@/modules/settings/views/settings-upgrade-plan.view.vue"),
        },
      ],
    },
    {
      path: "/heartbeat",
      name: "heartbeat",
      component: () => import("@/modules/e-commerce/heartbeat.view.vue"),
    },
    {
      path: "/monitoring",
      name: "monitoring",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/monitoring-analysis/monitoring.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/analysis",
      name: "analysis",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/monitoring-analysis/analysis.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/monitoring",
      name: "monitoring",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/monitoring-analysis/monitoring.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/analysis",
      name: "analysis",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/monitoring-analysis/analysis.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/all-channels-analysis",
      name: "all_channels_analysis",
      component: () =>
        import(
          "@/modules/all-channels-analysis/all-channels-analysis.view.vue"
        ),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/all-channels-analysis/:project",
      name: "all_channels_analysis_project",
      component: () =>
        import(
          "@/modules/all-channels-analysis/all-channels-analysis-project.view.vue"
        ),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/auth/invite/:hash?",
      name: "inviteRegistration",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/onboarding/inviteRegistration.vue"),
      meta: {
        layout: "auth",
        public: true,
      },
    },
    {
      path: "/auth/user-invite/:hash?",
      name: "user-invite",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/onboarding/user-invite-screen.vue"),
      meta: {
        layout: "auth",
        public: true,
      },
    },
    {
      // Some weird regex for a catch-all case outlined here
      // https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import("@/views/auth/404.vue"),
      meta: {
        layout: "auth",
        public: true,
      },
    },
    {
      path: "/projects/:projectId/create-anomaly-rule",
      name: "create_anomaly_rule",
      component: () =>
        import("@/modules/projects/anomaly/create-anomaly-rule.vue"),
    },
    {
      path: "/projects/:projectId/edit-anomaly-rule",
      name: "edit_anomaly_rule",
      component: () =>
        import("@/modules/projects/anomaly/edit-anomaly-rule.vue"),
    },
    {
      path: "/events",
      name: "events_dashboard",
      component: () => import("@/views/events-dashboard.vue"),
    },
  ],
});

const toast = useToast();

router.onError((error) => {
  if (import.meta.env.PROD) {
    const isFailedToFetchError = error.message.includes(
      "Failed to fetch dynamically imported module",
    );

    if (isFailedToFetchError) {
      /**
       * When we deploy a new version the app will break for people that were
       * using the app during the release.
       * Reason is, some of the files referenced by the old components may have different
       * hashes in the new release, so they will not exist on the server.
       * This needs to be done with some sort of versioning mechanism, but this is a
       * simple temporary way to catch some of the errors.
       * This is not a reliable solution because it only works when the user is navigating between the pages
       * and there are other scenarios where this can occur.
       * Adding this now as it often leads to false bug reports about broken features that work
       * after a reload
       */
      if (import.meta.env.PROD)
        toast.info(
          `Seems like a new version of the app is available.\n Please use CTRL SHIFT R to reload the page`,
          {
            id: "new_version",
            timeout: false,
          },
        );
    }
  }
});

/**
 * If a route does not have `public: true` in its meta properties, it is
 * assumed to be private and is only accessible if logged in.
 * * This hook runs when the user is navigating within the application.
 * We want to check if he's logged in because his session may have expired since he entered.
 * Some of the routes have their own `beforeEnter` hook, which is a route-level redirect
 */
router.beforeResolve((to, from) => {
  const store = useUserStore();
    const gtmData:any = {
        user_id: store?.user?.id,
        company_id: store?.company?.id,
        user_email: store?.user?.email,
        company_name: store?.company?.name,
        subscription_plan: null,
        //   project_id: "",
        user_role: store?.member?.role?.name,
        total_company_revenue: null
    }

  if(to.params.project){
    gtmData.projectId = to.params.project
  }

  if(to.params.projectId){
    gtmData.projectId = to.params.projectId
  }
  pushDataLayer(gtmData)

  // Anybody can access public routes
  if (to.meta.public === true) {
    return true;
  }

  // If he hwasnt logged in at all, redirect to the login page
  if (store.isGuest) {
    if (to.name === "login" || to.name === "inviteRegistration") {
      return true;
    }

    return {
      name: to.params.hash ? "inviteRegistration" : "login",
      params: {
        hash: to.params.hash,
      },
    };
  }

  // If he's logged in but no company is selected, redirect to company selection page
  if (store.isAuthenticatedAsMember === false) {
    const whitelistedRoutesWhenNotAuthenticatedAsMember = [
      "auth_company",
      "create-company",
      "register",
      "login",
    ];

    const isAllowedRouteAllowed =
      whitelistedRoutesWhenNotAuthenticatedAsMember.includes(to.name);

    if (isAllowedRouteAllowed) {
      return true;
    }

    return {
      name: "auth_company",
    };
  }

  return true;
});

export default router;
