<template>
  <svg width="217" height="73" viewBox="0 0 217 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.4002 65.2L16.2002 66.6L43.8002 72.8L44.4002 65.2Z" fill="#D91C49"/>
    <path d="M43.9001 54.6999L0.100098 43.8999L19.1001 63.0999L43.4001 62.5999L43.9001 54.6999Z" fill="#D91C49"/>
    <path d="M49.1999 44.3L20.2999 36.7L4.3999 18.2L10.6999 42.7L40.0999 51.3999L45.5999 50.5L49.1999 44.3Z" fill="#D91C49"/>
    <path d="M50.2999 36.2L26.6999 26.8L14.5999 0.199951L10.3999 19.4L19.8999 31.4L46.6999 41.8L50.2999 42.5V36.2Z" fill="#D91C49"/>
    <path d="M77.1001 44.8H71.2001V65.3999H67.1001V44.8H61.1001V41.7H77.6001L77.1001 44.8Z" fill="black"/>
    <path d="M88.3999 41.6001C94.1999 41.6001 96.7999 44.0001 96.7999 48.1001C96.7999 50.8001 95.4999 53.1001 92.3999 54.0001C94.9999 55.5001 96.2999 59.2001 98.9999 65.4001H94.4999C91.2999 58.0001 90.0999 55.1001 86.5999 55.1001H85.5999V65.4001H81.3999V41.6001C82.0999 41.7001 86.5999 41.6001 88.3999 41.6001ZM88.1999 52.1001C90.7999 52.1001 92.2999 50.9001 92.2999 48.6001C92.2999 46.5001 91.2999 44.6001 87.7999 44.6001H85.3999V52.1001H88.1999Z" fill="black"/>
    <path d="M112.9 41.7L122.1 65.5H117.6L115.4 59.3H106.8L104.6 65.5H100.5L109.6 41.7H112.9ZM111.2 46.3C110.6 48.4 109.4 52 108 56.1H114.4C112.9 52 111.7 48.3 111.2 46.3Z" fill="black"/>
    <path d="M139.1 61.4999L140.1 64.4999C140.1 64.4999 137.4 65.6999 133.9 65.6999C127.7 65.6999 124.5 61.5999 124.5 53.8999C124.5 46.4999 127.8 41.3999 134.9 41.3999C137.7 41.3999 139.4 42.5999 140.6 43.8999L138.5 46.4999C137.4 45.2999 136.2 44.5999 134.6 44.5999C130.6 44.5999 129.2 48.4999 129.2 53.4999C129.2 58.3999 130.4 62.4999 134.9 62.4999C136.8 62.3999 138.8 61.5999 139.1 61.4999Z" fill="black"/>
    <path d="M148.6 65.4001H144.4V41.6001H148.6V65.4001ZM163 65.4001H158.2L149.1 52.5001V52.3001C153.4 50.7001 155.6 47.0001 156.8 41.6001H160.9C159.4 48.6001 157.3 51.0001 154.3 53.0001L163 65.4001Z" fill="black"/>
    <path d="M170.3 41.7H166.1V65.5H170.3V41.7Z" fill="black"/>
    <path d="M185.9 41.7L195.1 65.5H190.6L188.4 59.3H179.8L177.6 65.5H173.5L182.6 41.7H185.9ZM184.1 46.3C183.5 48.4 182.3 52 180.9 56.1H187.3C185.8 52 184.7 48.3 184.1 46.3Z" fill="black"/>
    <path d="M216.4 41.7001V65.5001H212.9L204.9 53.0001C204 51.7001 202.9 49.8001 201.9 48.3001C202 49.8001 202.2 51.5001 202.2 53.0001L202.3 65.4001H198.1V41.6001H201.4L209.7 54.0001C210.6 55.3001 211.6 56.9001 212.5 58.4001C212.4 56.9001 212.3 55.3001 212.3 54.2001L212.2 41.8001H216.4V41.7001Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "main-logo-big"
}
</script>

<style scoped>

</style>