<template>
  <div
    class="z-0 py-2 my-1 mx-4 transition-all ease-linear duration-300 flex font-bold cursor-pointer"
  >
    <a href="/logout" @click="logout">
      <t-icon
        is="arrow-right-from-bracket"
        class="text-gray-800 group-hover:text-black text-md fa-fw"
      />
      <span class="text-base font-medium font-sans px-4 group-hover:text-black"
        >Logout</span
      >
    </a>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia/dist/pinia";
import { useUserStore } from "../../stores/user-store";
import TIcon from "@/components/atoms/t-icon.vue";
import { useQueryClient } from "vue-query";
export default {
  name: "logout-user",
  components: { TIcon },
  created() {
    this.fetchUserOnce();
  },
  setup() {
    const queryClient = useQueryClient();
    return { queryClient };
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
  },
  methods: {
    ...mapActions(useUserStore, {
      reallyLogout: "logout",
      fetchUserOnce: "reload",
    }),
    async logout(e) {
      e.preventDefault();
      try {
        await this.reallyLogout();

        // This removes all queries from Vue query, which effectively
        // clears the cache. Otherwise, after logging in another company user will see
        // data from the old company until the new data is fetched
        this.queryClient.removeQueries()

        await this.$router.push({ name: "login" });
      } catch (error) {
        console.log(error?.response || error);
      }
    },
  },
};
</script>

<style scoped></style>
