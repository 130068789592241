import { isAxiosError } from "@/modules/common/utils";
import type { ProjectGroup } from "@/modules/projects/groups/use-project-groups-query";
import { defineStore } from "pinia";

export type Project = {
  id: number;
  name: string;
  group: ProjectGroup;
  projectGroupId: number;
  status: boolean;
};

export type PlatformProfile = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  profileId: string;
};

export type Platform = {
  id: number;
  name: string;
  code: string;
  profiles: PlatformProfile[];
};

export type StoreState = {
  projects: Project[];
  platforms: Platform[];
  loading: boolean;
  error?: Error;
};

/**
 * @TODO @diyan deprecate this
 */
export const useProjectsStore = defineStore({
  id: "projects",
  state: (): StoreState => ({
    projects: [],
    platforms: [],
    loading: false,
  }),
  getters: {
    getIds: (state) => {
      return state.projects.map((project) => project.id);
    },
    getActiveProjects: (state) =>
      state.projects.filter((project) => project.status),
  },
  actions: {
    getProjectsByGroupId(groupId: number) {
      return this.projects.filter((project) => project.group.id === groupId);
    },
    async reload() {
      this.loading = true;

      try {
        const platforms = await this.$axios.get("/platforms");

        this.platforms = platforms.data;

        const platformIds = platforms.data
          .map((platform: any) => platform.id)
          .join(",");

        const { data } = await this.$axios.get(
          `/projects/menu?dataSourcePlatforms=${platformIds}`,
        );

        this.loading = false;

        this.projects = data.nodes;
      } catch (error) {
        this.error = new Error("Couldn't load projects");
        this.loading = false;
        if (!isAxiosError(error)) {
          this.$sentry.captureException(error);
        }
      }
    },

    /**
     * This action fetches the groups only if they weren't fetched before
     */
    async get() {
      if (this.projects.length == 0) {
        await this.reload();
      }
    },
  },
});
