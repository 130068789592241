import { defineStore } from "pinia";

import socket from "@/modules/notifications/";
import { useToast } from "vue-toastification";
import { uniqBy } from "lodash";

const toast = useToast();

type Notification = {
  type: string;
  params: any;
};

const hash = localStorage.getItem("passport");
const memberId = localStorage.getItem("memberId");
const companyId = localStorage.getItem("companyId");

export const useNotificationsStore = defineStore({
  id: "notifications",
  state: () => ({
    isConnected: false,
    notifications: [],
  }),
  actions: {
    markAllAsRead() {
      socket.emit("notifications-mark-read", {
        hash,
        notifications: this.notifications.map((n) => n.id),
      });

      for (const notification of this.notifications) {
        notification.status = "read";
      }
    },
    bindEvents() {
      socket.on("notifications", (notifications) => {
        // remove duplicates by id
        this.notifications = uniqBy(
          [...notifications, ...this.notifications],
          "id",
        ).sort((a, b) => {
          const statuses = ["read", "created"];
          return statuses.indexOf(a.status) < statuses.indexOf(b.status)
            ? -1
            : 1;
        });
      });

      socket.on(`company_${companyId}`, (notification) => {
        toast.info(notification.title, {
          id: `notification_${notification.id}`,
          timeout: 3000,
        });

        this.notifications = [notification, ...this.notifications];
      });

      socket.on("disconnect", () => {
        this.isConnected = false;
      });

      socket.on("connect", () => {
        this.isConnected = true;

        socket.emit("get-notifications", { hash });
      });

      socket.on("connected", () => {
        this.isConnected = true;

        socket.emit("get-notifications", { hash });
      });

      socket.on(`member_${memberId}`, (notification, Notification) => {
        if (notification?.params?.layout == "failed_source") {
          toast.error(notification.description, {
            id: `notification_${notification.id}`,
            timeout: 3000,
          });
        } else {
          toast.info(notification.title, {
            id: `notification_${notification.id}`,
            timeout: 3000,
          });
        }

        socket.emit("notification-mark-read", {
          hash,
          notificationId: notification.id,
        });
      });

      socket.on("notification", (notification) => {
        if (notification?.params?.layout == "failed_source") {
          toast.error(notification.description, {
            id: `notification_${notification.id}`,
            timeout: false,
          });
        } else {
          toast.info(notification.title, {
            id: `notification_${notification.id}`,
            timeout: 3000,
          });
        }

        this.notifications = [notification, ...this.notifications];
      });

      this.connect();
    },

    connect() {
      socket.connect();
    },
  },
});
