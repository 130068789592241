import { inject } from "vue";

let uid = 0;

export const inputUid = () => {
  uid++;
  return `t-input-${uid}`;
};

export type FormControlContext = {
  id: string;
  required: boolean;
  invalid: boolean;
};

export const FORM_CONTROL_CONTEXT = Symbol("FormControlContext");

export function useFormControl() {
  const context = inject<FormControlContext>(FORM_CONTROL_CONTEXT, null);

  return context;
}
